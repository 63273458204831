<template>
  <div>
    <div
      class="relative flex items-center gap-2"
      :class="[containerClass]"
    >
      <input
        :id="id"
        ref="input"
        :model-value="modelValue"
        type="checkbox"
        class="h-4 w-4 rounded border focus:ring-0 focus:ring-offset-0 sm:left-6"
        :class="[
          disabled ? 'bg-light-grey-2 border-grey-9 text-grey-4 cursor-default' : 'border-gray-300 text-blue-4 cursor-pointer',
          { 'opacity-50': readonly }
        ]"
        :checked="isChecked"
        :disabled="disabled || readonly"
        :indeterminate="indeterminate"
        :value="value"
        @change="onChange"
      >

      <label
        v-if="label || $slots.label"
        :for="!$slots.label || labelClickToggleChecked ? id : ''"
        class="block text-sm"
        :class="[
          { 'cursor-pointer': !disabled && !readonly },
          { 'field-required': required },
          disabled ? 'text-grey-4' : 'text-gray-700',
          labelClass
        ]"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </label>
    </div>

    <div
      v-if="sublabel"
      class="ml-6 text-sm mt-1"
      :class="{ 'text-grey-4': disabled }"
    >
      <p class="description-text">
        {{ sublabel }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, Boolean, Array, Number],
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: true
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    sublabel: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: [String, Object, Array],
      default: ''
    },
    labelClickToggleChecked: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    // Inject
    const _ = inject('lodash');

    // Data
    const id = ref(_.uniqueId());
    const input = ref();

    // Computed
    const isChecked = computed(() => {
      if (props.indeterminate) {
        return true;
      }

      if (Array.isArray(props.modelValue)) {
        return props.modelValue.includes(props.value) ? props.value : false;
      }

      return props.modelValue === props.value;
    });

    // Methods
    const onChange = e => {
      const checked = e?.target?.checked;
      input.value.checked = true;

      if (Array.isArray(props.modelValue)) {
        const values = props.modelValue.slice();
        const index = values.findIndex(value => value === props.value);

        if (index === -1 && checked) {
          values.push(props.value);
        }

        if (index !== -1 && !checked) {
          values.splice(index, 1);
        }

        context.emit('update:modelValue', values);
        return;
      }

      context.emit('update:modelValue', checked ? props.value : false);
    };

    return {
      id,
      input,
      isChecked,
      onChange
    };
  }
};
</script>

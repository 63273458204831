import { computed } from 'vue';
import i18n from '@/i18n';
import store from '@/store';
// Composables
import useRequest from '@/composables/useRequest';
import useTexts from '@/composables/useTexts';
import usePermissions from '@/composables/usePermissions';
// Constants
import ALL_OPTIONS from '@/constants/options';
// Utils
import { helpers } from '@/utils/helpers';
// Services
import TagsService from '@/services/hrsg/content/tags/TagsService';
import SkillService from '@/services/hrsg/content/libraries/SkillService';
import CompetencyService from '@/services/hrsg/content/libraries/CompetencyService';
import JobService from '@/services/hrsg/content/jobs/JobService';
import ScaleService from '@/services/hrsg/administration/ScaleService';
import GuideService from '@/services/hrsg/content/interviews/GuideService';
// Constants
import tableHeaders from '@/constants/tableHeaders';

export default function useOptions () {
  // Misc
  const t = i18n.global.t;

  // Composables
  const { request, getArrayResponse } = useRequest();
  const { getText } = useTexts();
  const { permissions, hasPermissions } = usePermissions();

  // Computed
  const languages = computed(() => store.getters['client/available_languages']);
  const userTableColumn = computed(() => store.getters['user/user']?.tables_columns);

  const languagesOptions = computed(() => languages.value.map(lang => ({
    text: t(lang),
    value: lang
  })));

  const allOptions = computed(() => Object.keys(ALL_OPTIONS).reduce((acc, key) => {
    const value = ALL_OPTIONS[key];

    return {
      ...acc,
      [key]: {
        text: t(...value.localeKey),
        subKey: value.localeSubKey ? t(...value.localeSubKey) : undefined,
        value: value.value
      }
    };
  }, {}));

  // Methods
  const getAllResources = async (endpoint, query = {}) => {
    const { perPage = -1 } = query;

    const response = await request({
      endpoint,
      queryParams: {
        ro_p: perPage
      }
    });

    return getArrayResponse(response);
  };

  const getUsersWithPosition = async (value = '') => {
    const response = await request({
      endpoint: 'administration.users.index',
      queryParams: {
        ro_r: ['positions'],
        ro_f: JSON.stringify([
          {
            f: ['position_name'],
            c: 'LIKE',
            v: value.split(' ')
          }
        ])
      }
    });

    return getArrayResponse(response).reduce((acc, x) => {
      const MAX_LENGTH = 36;
      const userName = helpers.getFullName(x);
      const userPositions = x?.positions.filter(helpers.isStatusActive);

      let user = [];
      if (userPositions?.length === 0) {
        user = [
          {
            ...x,
            user_name: userName,
            position_id: null,
            user_position_id: null,
            value: x.id,
            text: `${helpers.truncate(userName, MAX_LENGTH).padEnd(25)}${helpers.truncate(t('no_positions'), MAX_LENGTH)}`
          }
        ];
      }

      return [
        ...acc,
        ...user,
        ...userPositions.map(userPosition => {
          const positionName = getText(userPosition?.position?.texts, 'name');

          return {
            ...x,
            user_name: userName,
            position_name: positionName,
            position_id: userPosition?.position?.id,
            user_position_id: userPosition?.id,
            value: `${x?.id}-${userPosition?.position?.id}`,
            text: `${helpers.truncate(userName, MAX_LENGTH).padEnd(25)}${helpers.truncate(positionName, MAX_LENGTH)}`
          };
        })
      ];
    }, []) ?? [];
  };

  const getTextsFilter = value => ({
    f: 'texts.name',
    c: 'LIKE',
    v: [value]
  });
  const getPublishedTextsFilter = {
    f: 'status',
    c: 'EQUAL',
    v: [ALL_OPTIONS.PUBLISHED.value]
  };

  const formatTextOptions = options => formatOptions({
    options,
    getTextOption: option => getText(option.texts, 'name')
  });

  // Dynamic
  const getCompetenciesOptions = async (value = '', filterByPublished = false) => {
    const filters = [getTextsFilter(value)];

    if (filterByPublished) {
      filters.push(getPublishedTextsFilter);
    }
    const options = await CompetencyService.index({
      ro_f: JSON.stringify(filters),
      ro_r: ['levels']
    });

    return formatTextOptions(options);
  };

  const getSkillsOptions = async (value = '') => {
    const options = await SkillService.index({
      ro_f: JSON.stringify([
        getTextsFilter(value)
      ])
    });

    return formatTextOptions(options);
  };

  // Cache
  const getPermissionSetsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'PermissionSet',
      getData: async () => {
        const options = await getAllResources('administration.permission-sets.index');

        return formatTextOptions(options);
      }
    });
  };

  const getLocationsOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'Location',
      getData: async () => {
        const options = await getAllResources('administration.locations.index');

        return formatTextOptions(options);
      }
    });
  };

  const getDepartmentsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'Department',
      getData: async () => {
        const options = await getAllResources('administration.departments.index');

        return formatTextOptions(options);
      }
    });
  };

  const getPositionsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'Position',
      getData: async () => {
        const options = await getAllResources('administration.positions.index');

        return formatTextOptions(options);
      }
    });
  };

  const getResponsibilityGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'ResponsibilityGroup',
      getData: async () => {
        const options = await getAllResources('content.responsibility-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getEducationGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'EducationGroup',
      getData: async () => {
        const options = await getAllResources('content.education-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getCertificationGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'CertificationGroup',
      getData: async () => {
        const options = await getAllResources('content.certification-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getSkillGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'SkillGroup',
      getData: async () => {
        const options = await getAllResources('content.skill-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getCompetencyGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'CompetencyGroup',
      getData: async () => {
        const options = await getAllResources('content.competency-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getTagsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'Tag',
      getData: async () => {
        const options = await TagsService.index(false, [], true);

        return formatTextOptions(options);
      }
    });
  };

  const getJobGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'JobGroup',
      getData: async () => {
        const options = await getAllResources('jobs.job-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getJobsOptions = async (value = '') => {
    const options = await JobService.index({
      ro_f: JSON.stringify([
        getTextsFilter(value),
        {
          f: 'status',
          c: 'EQUAL',
          v: [
            ALL_OPTIONS.PUBLISHED.value
          ]
        }
      ])
    });

    return formatTextOptions(options);
  };

  const searchJobs = async (value = '', filters = [], query = {}) => {
    const options = await JobService.index({
      ro_f: JSON.stringify([
        getTextsFilter(value),
        ...filters
      ]),
      ...query
    });

    return formatTextOptions(options);
  };

  const searchGuides = async (value, filters = []) => {
    const options = await GuideService.index({
      ro_f: JSON.stringify([
        getTextsFilter(value),
        ...filters
      ])
    });

    return formatTextOptions(options);
  };

  const searchGuideTemplates = async (value = '', filters = []) => {
    return await searchGuides(value, [
      {
        f: 'type',
        c: 'EQUAL',
        v: ['Template']
      },
      ...filters
    ]);
  };

  const getAdditionalInformationGroupsOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'AdditionalInformationGroup',
      getData: async () => {
        const options = await getAllResources('content.additional-information-groups.index');

        return formatTextOptions(options);
      }
    });
  };

  const getUserPositionOptions = async (value = '') => {
    const response = await request({
      endpoint: 'administration.positions.search-users-positions',
      queryParams: {
        search_term: value
      }
    });

    return getArrayResponse(response).map(x => {
      const MAX_LENGTH = 36;

      return {
        ...x,
        value: x.position_id,
        text: `${helpers.truncate(x.position_name, MAX_LENGTH).padEnd(50)}${helpers.truncate(x.user_name, MAX_LENGTH)}`
      };
    }) ?? [];
  };

  const getTopicOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'InterviewQuestionTopic',
      getData: async () => {
        const options = await getAllResources('content.interview-questions.topics.index');

        return formatTextOptions(options);
      }
    });
  };

  const getGuideCommunicationsOptions = async () => {
    const options = await getAllResources('content.interview-guides.communications.index');

    return formatOptions({
      options,
      getTextOption: option => getText(option.texts, 'name')
    });
  };

  const getSalaryOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'Salary',
      getData: async () => {
        const options = await getAllResources('administration.job-salaries.index');

        return formatOptions({
          options,
          getTextOption: option => getText(option.texts, 'name')
        });
      }
    });
  };

  const getJobLevelOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'JobLevel',
      getData: async () => {
        const options = await getAllResources('administration.job-levels.index');

        return formatOptions({
          options,
          getTextOption: option => getText(option.texts, 'name')
        });
      }
    });
  };

  const getJobTypeOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'JobType',
      getData: async () => {
        const options = await getAllResources('administration.job-types.index');

        return formatOptions({
          options,
          getTextOption: option => getText(option.texts, 'name')
        });
      }
    });
  };

  const getGuideGroupsOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'InterviewGuideGroup',
      getData: async () => {
        const options = await getAllResources('content.interview-guides.groups.index');

        return formatOptions({
          options,
          getTextOption: option => getText(option.texts, 'name')
        });
      }
    });
  };

  const getJobPropertyOptions = async () => {
    return store.dispatch('options/getOptions', {
      resource: 'JobProperty',
      getData: async () => {
        const options = await getAllResources('administration.job-properties.index');

        return formatOptions({
          options,
          getTextOption: option => getText(option.texts, 'name')
        });
      }
    });
  };

  const getScalesLevelOptions = async (endpoint) => {
    const scale = await new ScaleService().getScaleByHandle(endpoint);
    if (scale && scale.enabled !== ALL_OPTIONS.YES.value) return [];

    return await store.dispatch('options/getOptions', {
      resource: `${endpoint}Level`,
      getData: async () => {
        const service = new ScaleService(`administration.scales.${endpoint}`);
        const options = await service.index();

        return formatTextOptions(options);
      }
    });
  };

  const getCompareJobsOptions = async value => {
    const options = await JobService.index({
      ro_f: JSON.stringify([
        getTextsFilter(value),
        {
          f: 'status',
          c: 'EQUAL',
          v: hasPermissions(permissions.MANAGE_JOBS) || hasPermissions(permissions.CONTENT_ADMINISTRATOR)
            ? [ALL_OPTIONS.PUBLISHED.value, ALL_OPTIONS.DRAFT.value, ALL_OPTIONS.DRAFT_COPY.value]
            : [ALL_OPTIONS.PUBLISHED.value]
        }
      ])
    });

    return options.reduce((acc, cv) => {
      if (cv?.draft_copy?.id) return acc;

      return [
        ...acc,
        {
          ...cv,
          text: getText(cv?.texts, 'name'),
          value: cv?.id
        }
      ];
    }, []);
  };

  const getCandidatesLatestStatusOptions = async () => {
    return await store.dispatch('options/getOptions', {
      resource: 'CandidateLatestStatus',
      getData: async () => {
        const options = await getAllResources('administration.candidacy-statuses.index');
        const filteredOptions = options.map(({ status, ...rest }) => rest);

        return formatTextOptions(filteredOptions);
      }
    });
  };

  const getCompetenciesByGroupsOptions = async (isWarehouse = false) => {
    return await store.dispatch('options/getOptions', {
      resource: 'CompetenciesByGroup',
      getData: async () => {
        const endpoint = isWarehouse ? 'content.warehouse.index' : 'content.competencies.index';
        const pathParams = isWarehouse ? { type: 'COMPETENCIES' } : {};

        const FILTER_STATUS = {
          f: 'status',
          c: 'EQUAL',
          v: [
            ALL_OPTIONS.DRAFT.value,
            ALL_OPTIONS.PUBLISHED.value
          ]
        };

        const competencies = await request({
          endpoint,
          pathParams,
          queryParams: {
            ro_p: -1,
            ro_f: JSON.stringify([
              FILTER_STATUS
            ])
          }
        });

        return getArrayResponse(competencies)?.reduce((acc, x) => {
          if (!acc[x.competency_group_id]) {
            acc[x.competency_group_id] = [];
          }

          acc[x.competency_group_id].push({
            ...x,
            text: getText(x?.texts, isWarehouse.value ? 'original_name' : 'name'),
            value: x?.id
          });

          return acc;
        }, {});
      }
    });
  };

  /**
   * Format options
   * @param {Object} args - object that contains the params below
   * @param {Array<Object>} options - the options to format
   * @param {String} textKey - defaults to text
   * @param {String} valueKey - defaults to value
   * @param {String} textOption - defaults to name
   * @param {String} valueOption - defaults to id
   * @param {Function} getTextOption
   * @param {Function} getValueOption
   * @returns the fomatted options
   */
  const formatOptions = args => {
    const {
      options = [],
      textKey = 'text',
      valueKey = 'value',
      textOption = 'name',
      valueOption = 'id',
      getTextOption,
      getValueOption
    } = args;

    return options.map(option => ({
      ...option,
      [textKey]: getTextOption ? getTextOption(option) : option[textOption],
      [valueKey]: getValueOption ? getValueOption(option) : option[valueOption]
    }));
  };

  const getUserTableColumn = tableKey => {
    const columns = userTableColumn.value?.[tableKey] ?? {};

    const final = tableHeaders[tableKey]
      ?.reduce((filtered, option) => {
        if (option.value in columns) {
          const isDisplay = (option?.display || (option?.display?.value ?? true)) && !(option?.hide?.value ?? false);

          filtered.push({
            ...option,
            id: columns[option?.value]?.id,
            text: getHeaderText(option),
            position: columns[option?.value]?.position,
            display: isDisplay && columns[option?.value]?.visible === 'Yes',
            hide: option?.hide?.value ?? false
          });
        } else {
          filtered.push({
            ...option,
            text: getHeaderText(option)
          });
        }

        return filtered;
      }, [])
      ?.sort((a, b) => a?.position - b?.position);

    return final?.length ? final : getTableHeaders(tableKey);
  };

  const getTableHeaders = tableKey => {
    const headers = tableHeaders[tableKey] ?? [];

    return headers?.map(x => ({ ...x, text: getHeaderText(x) }));
  };

  const getHeaderText = header => {
    if (header?.name) return header.name;

    return header?.localeKey && Array.isArray(header.localeKey) ? t(...header.localeKey) : '';
  };

  const isUserTableColumnExist = tableKey => !!userTableColumn.value?.[tableKey];

  return {
    languages,
    languagesOptions,
    ALL_OPTIONS,
    allOptions,
    getTextsFilter,
    formatOptions,
    formatTextOptions,
    getAllResources,
    isUserTableColumnExist,
    getUsersWithPosition,
    // Custom
    getCompareJobsOptions,
    // Options
    getSkillsOptions,
    getUserPositionOptions,
    getResponsibilityGroupsOptions,
    getSkillGroupsOptions,
    getPermissionSetsOptions,
    getLocationsOptions,
    getDepartmentsOptions,
    getCompetenciesOptions,
    getCompetencyGroupsOptions,
    getCompetenciesByGroupsOptions,
    getPositionsOptions,
    getEducationGroupsOptions,
    getCertificationGroupsOptions,
    getJobGroupsOptions,
    getJobsOptions,
    getGuideGroupsOptions,
    getAdditionalInformationGroupsOptions,
    getTagsOptions,
    getTopicOptions,
    getGuideCommunicationsOptions,
    getUserTableColumn,
    getSalaryOptions,
    getJobLevelOptions,
    getJobTypeOptions,
    getJobPropertyOptions,
    getScalesLevelOptions,
    getCandidatesLatestStatusOptions,
    // Search
    searchJobs,
    searchGuideTemplates
  };
}

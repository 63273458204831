import { ref } from 'vue';

export default function useTabs (defaultTab = '') {
  // Data
  const activeTab = ref(defaultTab);

  // Methods
  const triggerTabClick = tab => {
    if (!tab.click) {
      return;
    }

    tab.click(tab);
  };

  const setActiveTab = value => {
    activeTab.value = value;
  };

  const isTabActive = value => activeTab.value === value;

  const onClickTab = tab => {
    setActiveTab(tab.value);
    triggerTabClick(tab);
  };

  return {
    activeTab,
    isTabActive,
    setActiveTab,
    onClickTab
  };
}

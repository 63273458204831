<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    :enabled="task.status === 'Open'"
    @click="onClick"
  >
    <template #default>
      <span>
        {{ $t('task_interview_candidate', { assigner: details?.candidate }) }}
        {{ details?.candidate }}
      </span>
    </template>

    <template #date>
      <span>
        {{ format(date, 'MMM D - h:mm A') }} {{ timezoneShortCode(date) }}
      </span>
    </template>

    <template #open>
      <VButton
        class="btn secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg round"
        :class="btnSize"
        @click="onClick"
      >
        {{ $t('open') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
// Composables
import useDate from '@/composables/useDate';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';

export default {
  components: {
    VTask,
    VButton
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // Misc
    const router = useRouter();

    // Constants
    const STATUSES = {
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      OPEN: 'Open'
    };

    // Composables
    const { format, timezoneShortCode } = useDate();

    // Computed
    const details = computed(() => JSON.parse(props.task?.details || null));

    const date = computed(() => {
      const statuses = {
        [STATUSES.COMPLETED]: props.task?.completed_at,
        [STATUSES.CANCELLED]: props.task?.cancelled_at,
        [STATUSES.OPEN]: details.value?.start_time
      };
      return statuses[props.task?.status] ?? '';
    });

    // Methods
    const onClick = () => {
      if (props.task?.status !== 'Open') {
        return;
      }

      router.push({
        path: details.value?.scorecard
      });
    };

    return {
      onClick,
      details,
      date,
      // useDate
      format,
      timezoneShortCode
    };
  }
};
</script>

import { computed } from 'vue';
// Composables
import usePermissions from '@/composables/usePermissions';
// Constants
import tableKeys from '@/constants/tableKeys';

const { permissions, features, hasPermissions, hasFeatures } = usePermissions();

// Methods
/**
 *
 * @param {Object} header - the header object
 * @param {Boolean} isRight - is it right aligned. Defaults to false
 * @returns {Object} the header object
 */
const getHeader = (header, isRight = false) => ({
  display: true,
  alwaysDisplay: true,
  ...header,
  headerClass: `text-grey-1 ${header.headerClass ?? ''} ${isRight ? 'text-right pr-2' : ''}`,
  cellClass: `${header.cellClass ?? ''} ${isRight ? 'text-right pr-2' : ''}`
});

// Constants
const LIBRARY_TAGS_HEADER = getHeader({
  localeKey: ['tags'],
  cellClass: 'min-w-[60px]',
  value: 'tags'
});

const LIBRARY_JOB_HEADER = getHeader({
  localeKey: ['jobs', 2],
  value: 'jobs',
  sortable: true,
  sortOrder: 'd',
  sortValue: 'jobsCount',
  headerClass: 'pr-6',
  cellClass: 'pr-6 min-w-[70px]'
}, true);

const STATUS_HEADER = getHeader({
  localeKey: ['statuses'],
  value: 'status',
  sortable: false,
  display: true
});

const LIBRARY_UPDATED_HEADER = getHeader({
  localeKey: ['updated'],
  value: 'updated',
  display: false,
  sortable: true,
  sortValue: 'updated_at',
  cellClass: 'min-w-[60px]'
}, true);

const ACTIONS_HEADER = getHeader({ value: '__ACTIONS' }, true);

const LIBRARY_ACTIONS_HEADER = getHeader({
  ...ACTIONS_HEADER,
  display: computed(() => hasPermissions(permissions.MANAGE_LIBRARIES))
});

const getAuditLogsHeaders = (hasItem = true) => [
  getHeader({
    localeKey: ['actions'],
    value: 'action',
    headerClass: 'pt-0'
  }),
  getHeader({
    localeKey: ['performed_by'],
    value: 'performedBy',
    headerClass: 'pt-0'
  }),
  getHeader({
    localeKey: ['ip_address'],
    value: 'ip',
    headerClass: 'pt-0'
  }),
  ...hasItem
    ? [
        getHeader({
          localeKey: ['items'],
          value: 'item',
          headerClass: 'pt-0'
        })
      ]
    : [],
  getHeader({
    localeKey: ['date'],
    value: 'date',
    headerClass: 'pt-0'
  }, true)
];

// Helpers methods
const getWarehouseHeaders = (itemText, groupText) => [
  getHeader({
    localeKey: itemText,
    value: 'item',
    sortable: true,
    sortValue: 'texts.original_name'
  }),
  getHeader({
    localeKey: groupText || ['groups', 2],
    value: 'group',
    sortable: true,
    sortValue: 'group'
  }),
  getHeader({
    localeKey: ['added'],
    value: 'added'
  }, true)
];

const getLibraryItemHeader = localeKey => getHeader({
  localeKey,
  fixed: true,
  value: 'item',
  sortable: true,
  sortValue: 'texts.name',
  cellClass: '!py-2.75'
});

const getLibraryGroupHeader = sortValue => getHeader({
  localeKey: ['groups'],
  value: 'group',
  sortable: true,
  sortValue
});

const getLibraryHeaders = (localeKey, sortValue, hasTags, hasJobs) => [
  getLibraryItemHeader(localeKey),
  STATUS_HEADER,
  ...sortValue ? [getLibraryGroupHeader(sortValue)] : [],
  ...hasTags ? [LIBRARY_TAGS_HEADER] : [],
  ...hasJobs ? [LIBRARY_JOB_HEADER] : []
];

const getLibraryArchiveHeaders = (localeKey, sortValue) => [
  getHeader({
    localeKey,
    value: 'item',
    sortable: true,
    sortValue: 'texts.name'
  }),
  ...sortValue ? [getLibraryGroupHeader(sortValue)] : [],
  getHeader({
    localeKey: ['archived'],
    value: 'archived',
    sortable: true,
    sortOrder: 'd',
    sortValue: 'archived_at'
  }, true),
  ACTIONS_HEADER
];

export default {
  [tableKeys.LEVEL_ANALYSIS_COMPETENCIES]: [
    getHeader({
      localeKey: ['competencies'],
      value: 'competency',
      sortable: true,
      sortValue: 'texts.name',
      display: true,
      fixed: true,
      cellClass: '!font-normal'
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.LEVEL_ANALYSIS_SKILLS]: [
    getHeader({
      localeKey: ['skills'],
      value: 'skill',
      sortable: true,
      sortValue: 'texts.name',
      display: true,
      fixed: true,
      cellClass: '!font-normal'
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.JOB_SIGNOFFS]: [
    getHeader({
      localeKey: ['employees'],
      value: 'employee',
      sortable: true,
      sortValue: 'user',
      display: true,
      fixed: true
    }),
    STATUS_HEADER,
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      sortable: true,
      sortValue: 'job',
      display: true
    }),
    getHeader({
      localeKey: ['departments'],
      value: 'department',
      sortable: true,
      sortValue: 'department',
      display: true
    }),
    getHeader({
      localeKey: ['signoff_due'],
      value: 'signoff_due',
      sortable: true,
      sortValue: 'due_at',
      display: true
    }, true),
    getHeader({
      localeKey: ['signoff_date'],
      value: 'signoff_date',
      sortable: true,
      sortValue: 'signed_at',
      display: true
    }, true),
    getHeader({
      localeKey: ['expiry'],
      value: 'expiry',
      sortable: true,
      sortValue: 'expires_at',
      display: false
    }, true),
    ACTIONS_HEADER
  ],
  [tableKeys.AUDIT_LOGS_JOBS]: [
    getHeader({
      localeKey: ['features', 2],
      value: 'module',
      headerClass: 'pt-0'
    }),
    ...getAuditLogsHeaders()
  ],
  [tableKeys.AUDIT_LOGS_SIGNOFFS]: [
    getHeader({
      localeKey: ['employees'],
      value: 'employee',
      headerClass: 'pt-0'
    }),
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      headerClass: 'pt-0'
    }),
    getHeader({
      localeKey: ['versions'],
      value: 'version',
      headerClass: 'pt-0'
    }, true),
    ...getAuditLogsHeaders(false)
  ],
  [tableKeys.AUDIT_LOGS_GENERAL]: [
    getHeader({
      localeKey: ['features', 2],
      value: 'module',
      headerClass: 'pt-0'
    }),
    ...getAuditLogsHeaders()
  ],
  [tableKeys.AUDIT_LOGS_INTERVIEWS]: [
    getHeader({
      localeKey: ['features', 2],
      value: 'module',
      headerClass: 'pt-0'
    }),
    ...getAuditLogsHeaders()
  ],
  [tableKeys.SURVEYS]: [
    getHeader({
      localeKey: ['surveys'],
      value: 'survey',
      sortable: true,
      sortValue: 'texts.name',
      alwaysDisplay: true,
      fixed: true
    }),
    STATUS_HEADER,
    getHeader({
      localeKey: ['response_rate'],
      value: 'response_rate',
      cellClass: 'min-w-[150px]',
      alwaysDisplay: true
    }),
    getHeader({
      localeKey: ['updated'],
      value: 'updated',
      sortable: true,
      sortValue: 'updated_at',
      alwaysDisplay: true,
      cellClass: 'min-w-[80px]'
    }, true),
    getHeader({
      localeKey: ['go_live'],
      value: 'go_live',
      sortable: true,
      sortValue: 'go_live_date',
      alwaysDisplay: true,
      cellClass: 'min-w-[80px]'
    }, true),
    getHeader({
      localeKey: ['due'],
      value: 'due',
      sortable: true,
      sortValue: 'due_date',
      alwaysDisplay: true,
      cellClass: 'min-w-[80px]'
    }, true),
    ACTIONS_HEADER
  ],
  [tableKeys.USERS]: [
    getHeader({
      localeKey: ['users'],
      value: 'user',
      sortable: true,
      sortValue: 'firstname',
      alwaysDisplay: true,
      fixed: true
    }),
    STATUS_HEADER,
    getHeader({
      localeKey: ['positions'],
      value: 'position',
      sortable: true,
      sortValue: 'position',
      cellClass: 'min-w-[60px]'
    }),
    getHeader({
      localeKey: ['managers'],
      value: 'manager',
      sortable: true,
      sortValue: 'reportsTo',
      cellClass: 'min-w-[60px]'
    }),
    /* getHeader({
      localeKey: ['job_sign_off'],
      value: 'job_sign_off',
      cellClass: 'min-w-[100px]',
      display: true
    }), */
    getHeader({
      localeKey: ['last_login'],
      value: 'last_login',
      sortable: true,
      sortOrder: 'd',
      sortValue: 'last_login',
      cellClass: 'min-w-[80px]'
    }, true),
    getHeader({
      localeKey: ['added'],
      value: 'added',
      sortable: true,
      sortOrder: 'd',
      sortValue: 'created_at',
      display: false,
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      localeKey: ['departments'],
      value: 'department',
      sortable: true,
      sortValue: 'department',
      display: false,
      cellClass: 'min-w-[80px]'
    }),
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      display: false,
      cellClass: 'min-w-[60px]'
    }),
    getHeader({
      localeKey: ['access_summary'],
      value: 'license_type',
      sortable: true,
      sortValue: 'license_type',
      display: false,
      cellClass: 'min-w-[80px]'
    }),
    getHeader({
      localeKey: ['locations'],
      value: 'location',
      sortable: true,
      sortValue: 'location',
      display: false
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.POSITIONS]: [
    getHeader({
      localeKey: ['positions'],
      value: 'position',
      sortable: true,
      sortValue: 'texts.name',
      fixed: true
    }),
    getHeader({
      localeKey: ['users'],
      value: 'user',
      sortable: true,
      sortValue: 'user'
    }),
    getHeader({
      localeKey: ['managers'],
      value: 'manager',
      sortable: true,
      sortValue: 'reportsTo'
    }),
    getHeader({
      localeKey: ['departments'],
      value: 'department',
      sortable: true,
      sortValue: 'department'
    }),
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      sortable: true,
      sortValue: 'job',
      display: false,
      cellClass: 'min-w-[60px]'
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.JOBS]: [
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      sortable: true,
      sortValue: 'texts.name',
      display: true,
      fixed: true
    }),
    STATUS_HEADER,
    getHeader({
      localeKey: ['review_by'],
      value: 'reviewDate',
      sortable: true,
      sortValue: 'next_review_at',
      display: false
    }, true),
    getHeader({
      localeKey: ['groups'],
      value: 'group',
      property: 'JOB_GROUP',
      sortable: true,
      sortValue: 'jobGroup',
      display: true
    }),
    getHeader({
      localeKey: ['due_date'],
      value: 'due_date',
      sortable: true,
      sortValue: 'due_date',
      display: computed(() => hasFeatures(features.PROGRESS_TRACKING)),
      hide: computed(() => !hasFeatures(features.PROGRESS_TRACKING))
    }, true),
    getHeader({
      localeKey: ['updated'],
      value: 'updated',
      sortable: true,
      sortValue: 'updated_at',
      sortOrder: 'd',
      display: true,
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      localeKey: ['progress'],
      value: 'progress',
      cellClass: 'min-w-[80px]',
      sortable: true,
      sortValue: 'progress',
      display: computed(() => hasFeatures(features.PROGRESS_TRACKING)),
      hide: computed(() => !hasFeatures(features.PROGRESS_TRACKING))
    }, true),
    getHeader({
      localeKey: ['job_codes'],
      value: 'code',
      property: 'JOB_CODE',
      cellClass: 'min-w-[80px]',
      sortable: true,
      sortValue: 'code',
      display: false
    }),
    getHeader({
      localeKey: ['levels', 2],
      value: 'level',
      property: 'JOB_LEVEL',
      sortable: true,
      sortValue: 'jobLevel',
      display: false,
      cellClass: 'min-w-[60px]'
    }),
    getHeader({
      localeKey: ['reports_to'],
      value: 'reports_to',
      property: 'REPORTS_TO',
      sortable: true,
      sortValue: 'reportsTo',
      display: false
    }),
    getHeader({
      localeKey: ['last_published'],
      value: 'last_published',
      property: 'LAST_PUBLISHED',
      cellClass: 'min-w-[170px]',
      sortable: true,
      sortValue: 'published_at',
      display: false
    }, true),
    getHeader({
      localeKey: ['salary', 2],
      value: 'salary',
      property: 'SALARY',
      cellClass: 'min-w-[60px]',
      sortable: true,
      sortOrder: 'd',
      sortValue: 'salary',
      display: false
    }),
    getHeader({
      localeKey: ['consistency'],
      value: 'consistency_score',
      property: 'CONSISTENCY',
      cellClass: 'min-w-[60px]',
      sortable: true,
      sortOrder: 'd',
      sortValue: 'consistency_score',
      display: computed(() => hasFeatures(features.CONSISTENCY_SCORES)),
      hide: computed(() => !hasFeatures(features.CONSISTENCY_SCORES))
    }, true),
    getHeader({
      ...ACTIONS_HEADER,
      display: computed(() => hasPermissions(permissions.MANAGE_JOBS))
    })
  ],
  // Library archives
  [tableKeys.JOBS_ARCHIVE]: getLibraryArchiveHeaders(['jobs'], 'jobGroup'),
  [tableKeys.RESPONSIBILITY_ARCHIVE]: getLibraryArchiveHeaders(['responsibilities', 2], 'responsibilityGroup'),
  [tableKeys.COMPETENCY_ARCHIVE]: getLibraryArchiveHeaders(['competencies', 2], 'competencyGroup'),
  [tableKeys.CERTIFICATION_ARCHIVE]: getLibraryArchiveHeaders(['certifications', 2], 'certificationGroup'),
  [tableKeys.ADDITIONAL_INFORMATION_ARCHIVE]: getLibraryArchiveHeaders(['additional_information', 2], 'additionalInformationGroup'),
  [tableKeys.EDUCATION_ARCHIVE]: getLibraryArchiveHeaders(['educations', 2], 'educationGroup'),
  [tableKeys.SKILLS_ARCHIVE]: getLibraryArchiveHeaders(['skills', 2], 'skillGroup'),
  [tableKeys.LANGUAGES_ARCHIVE]: getLibraryArchiveHeaders(['languages', 2]),
  [tableKeys.WORKING_CONDITIONS_ARCHIVE]: getLibraryArchiveHeaders(['working_conditions', 2]),
  [tableKeys.EXPERIENCES_ARCHIVE]: getLibraryArchiveHeaders(['experiences', 2]),
  // Libraries
  [tableKeys.RESPONSIBILITIES]: [
    ...getLibraryHeaders(['responsibilities', 2], 'responsibilityGroup'),
    getHeader({
      localeKey: ['jobs', 2],
      value: 'jobs',
      sortable: true,
      sortOrder: 'd',
      sortValue: 'jobsCount',
      cellClass: 'min-w-[60px]',
      display: false
    }, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.COMPETENCIES]: [
    ...getLibraryHeaders(['competencies', 2], 'competencyGroup', true, true),
    getHeader({
      localeKey: ['levels', 2],
      value: 'level',
      cellClass: 'min-w-[60px]',
      sortable: true,
      sortValue: 'levelsCount',
      display: false
    }, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.SKILLS]: [
    ...getLibraryHeaders(['skills', 2], 'skillGroup', true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.EDUCATION]: [
    ...getLibraryHeaders(['educations', 2], 'educationGroup', true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.ADDITIONAL_INFORMATION]: [
    ...getLibraryHeaders(['additional_information', 2], 'additionalInformationGroup', true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.LANGUAGES]: [
    ...getLibraryHeaders(['languages', 2], false, true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.EXPERIENCES]: [
    ...getLibraryHeaders(['experiences', 2], false, true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.WORKING_CONDITIONS]: [
    ...getLibraryHeaders(['working_conditions', 2], false, true, true),
    LIBRARY_UPDATED_HEADER,
    LIBRARY_ACTIONS_HEADER
  ],
  [tableKeys.CERTIFICATION]: [
    getLibraryItemHeader(['certifications', 2]),
    getLibraryGroupHeader('certificationGroup'),
    STATUS_HEADER,
    LIBRARY_TAGS_HEADER,
    LIBRARY_JOB_HEADER,
    LIBRARY_UPDATED_HEADER,
    getHeader({
      localeKey: ['levels', 2],
      cellClass: 'min-w-[60px]',
      value: 'level',
      display: false
    }, true),
    LIBRARY_ACTIONS_HEADER
  ],
  // Warehouse
  [tableKeys.WAREHOUSE_COMPETENCIES]: getWarehouseHeaders(['competencies']),
  [tableKeys.WAREHOUSE_RESPONSIBILITIES]: getWarehouseHeaders(['responsibilities']),
  [tableKeys.WAREHOUSE_SKILLS]: getWarehouseHeaders(['skills']),
  [tableKeys.WAREHOUSE_CERTIFICATIONS]: getWarehouseHeaders(['certifications']),
  [tableKeys.WAREHOUSE_EDUCATION]: getWarehouseHeaders(['educations']),
  [tableKeys.WAREHOUSE_JOBS]: getWarehouseHeaders(['jobs']),
  [tableKeys.WAREHOUSE_INTERVIEW_QUESTIONS]: getWarehouseHeaders(['questions'], ['competencies', 2]),
  // Interviews
  [tableKeys.OPENINGS]: [
    getHeader({
      localeKey: ['titles'],
      value: 'title',
      fixed: true,
      sortable: true,
      sortValue: 'texts.name'
    }),
    {
      ...STATUS_HEADER,
      display: true
    },
    getHeader({
      localeKey: ['hiring_managers'],
      sortable: true,
      sortValue: 'hiring_manager',
      value: 'manager'
    }),
    getHeader({
      localeKey: ['candidates', 2],
      value: 'candidates',
      sortable: true,
      sortValue: 'candidates_count'
    }, true),
    getHeader({
      localeKey: ['created'],
      value: 'created',
      sortable: true,
      sortValue: 'created_at',
      sortOrder: 'd',
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      localeKey: ['updated'],
      value: 'updated',
      sortable: true,
      sortValue: 'updated_at',
      sortOrder: 'd',
      cellClass: 'min-w-[60px]'
    }, true),
    ACTIONS_HEADER,
    getHeader({
      localeKey: ['positions'],
      value: 'position',
      display: false
    }),
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      display: false
    }),
    getHeader({
      localeKey: ['locations'],
      value: 'location',
      display: false
    }),
    getHeader({
      localeKey: ['departments'],
      value: 'department',
      display: false
    }),
    getHeader({
      localeKey: ['rounds', 2],
      value: 'rounds',
      sortable: true,
      sortValue: 'rounds_count',
      display: false
    }, true)
  ],
  [tableKeys.CANDIDATES]: [
    getHeader({
      localeKey: ['candidates'],
      value: 'candidate',
      sortable: true,
      sortValue: 'firstname'
    }),
    getHeader({
      localeKey: ['applications', 2],
      value: 'applications'
    }, true),
    getHeader({
      localeKey: ['email'],
      value: 'email'
    }),
    getHeader({
      localeKey: ['created'],
      value: 'created',
      sortable: true,
      sortValue: 'created_at',
      sortOrder: 'd',
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      localeKey: ['updated'],
      value: 'updated',
      sortable: true,
      sortValue: 'updated_at',
      sortOrder: 'd',
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      localeKey: ['latest_status'],
      value: 'latest_status',
      display: false
    }),
    getHeader({
      localeKey: ['linkedin'],
      value: 'linkedin',
      display: false
    }),
    getHeader({
      localeKey: ['personal_weblink'],
      value: 'website',
      display: false
    }),
    // getHeader({
    //   localeKey: ['resumes'],
    //   value: 'resume',
    //   display: false
    // }),
    ACTIONS_HEADER
  ],
  [tableKeys.GUIDES]: [
    getHeader({
      localeKey: ['titles'],
      value: 'title',
      fixed: true,
      sortable: true,
      sortValue: 'texts.name'
    }),
    STATUS_HEADER,
    getHeader({
      localeKey: ['jobs'],
      value: 'job',
      sortable: true,
      sortValue: 'jobName',
      cellClass: 'min-w-[60px]'
    }),
    getHeader({
      localeKey: ['groups'],
      value: 'group',
      sortable: true,
      sortValue: 'interviewGuideGroup'
    }),
    getHeader({
      localeKey: ['job_version'],
      value: 'jobVersion',
      sortable: false,
      display: false,
      sortValue: 'jobVersion'
    }),
    getHeader({
      localeKey: ['download'],
      value: 'download',
      headerClass: 'text-center',
      cellClass: 'text-center min-w-[150px] !pb-0'
    }),
    getHeader({
      localeKey: ['updated'],
      value: 'updated',
      sortable: true,
      sortValue: 'updated_at',
      sortOrder: 'd',
      cellClass: 'min-w-[60px]'
    }, true),
    getHeader({
      ...ACTIONS_HEADER,
      display: computed(() => hasPermissions(permissions.MANAGE_INTERVIEWS))
    })
  ],
  [tableKeys.TOPIC_QUESTIONS]: [
    getHeader({
      localeKey: ['questions'],
      value: 'question',
      fixed: true,
      sortable: true,
      sortValue: 'texts.name'
    }),
    getHeader({
      localeKey: ['topics'],
      value: 'topic'
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.COMPETENCY_QUESTIONS]: [
    getHeader({
      localeKey: ['questions'],
      value: 'question',
      fixed: true,
      sortable: true,
      sortValue: 'texts.name'
    }),
    getHeader({
      localeKey: ['competencies'],
      value: 'competencyValue',
      sortable: true,
      sortValue: 'competency'
    }),
    getHeader({
      localeKey: ['levels'],
      value: 'level',
      sortable: true,
      cellClass: 'min-w-[50px]',
      sortValue: 'competencyLevel'
    }),
    ACTIONS_HEADER
  ],
  [tableKeys.API_KEYS]: [
    getHeader({
      localeKey: ['name'],
      value: 'name',
      fixed: true,
      sortable: false,
      headerClass: '!pl-0',
      cellClass: '!pl-0'
    }),
    getHeader({
      localeKey: ['api_keys'],
      value: 'token',
      sortable: false
    }),
    getHeader({
      localeKey: ['created'],
      value: 'created',
      sortable: false
    }),
    ACTIONS_HEADER
  ]
};

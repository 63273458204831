import store from '@/store';

export default function useToast () {
  // Methods
  /**
   * @param {Array|Object} response - the response from an api call
   * @param {String} successMessage - the message to show if the call was successful
   * @param {String} status - Status displayed the colors. Possible options are 'Success', 'Failure', 'Warning' and 'Info'. Defaults to 'Success'.
   * @param {String} delay - Delay (in ms) before the toast is hidden. Defaults to 3000ms.
   * @param {Number} delayToast - Delay (in ms) before toast is shown. Defaults to 500ms.
   */
  const showResponseToast = (response, successMessage, displayedStatus = 'Success', delay = 3000, delayToast = 500) => {
    let status, text;
    const successStatus = 'Success';
    const failureStatus = 'Failure';

    if (Array.isArray(response)) {
      const failureResponse = response.find(r => r?.payload?.message?.status === failureStatus);
      const { status: responseStatus, text: responseMessage } = failureResponse?.payload?.message ?? {};
      status = responseStatus ?? displayedStatus;
      text = responseMessage ?? successMessage;
    } else {
      const { status: responseStatus, text: responseMessage } = response?.payload?.message ?? {};
      status = responseStatus === successStatus ? displayedStatus : responseStatus;
      text = responseStatus === successStatus ? successMessage : responseMessage;
    }

    if (!text) {
      return;
    }

    showToast(text, status, delay, delayToast);
  };

  /**
   * Show toast
   * @param {String} text - Message shown in the toast
   * @param {String} status - Status displayed the colors. Possible options are 'Success', 'Failure', 'Warning' and 'Info'.
   * @param {String} delay - Delay (in ms) before the toast is hidden. Defaults to 3000ms.
   * @param {Number} delayToast - Delay (in ms) before toast is shown. Defaults to 500ms.
   */
  const showToast = (text, status, delay = 3000, delayToast = 500) => {
    setTimeout(() => {
      store.dispatch('toast/show', { text, status, delay });
    }, delayToast);
  };

  return {
    showResponseToast,
    showToast
  };
}

import axios from 'axios';
import i18n from '@/i18n';
const t = i18n.global.t;

const state = () => ({
  client: {},
  hasLoaded: false,
  hasSiteUpdates: false,
  activeLibraries: []
});

const getters = {
  hasLoaded: state => state.hasLoaded,
  after_logout_url: (state) => state.client?.after_logout_url,
  logout_timer: state => state.client?.logout_timer ?? 0,
  api_url: (state) => state.client?.api_url,
  app_url: (state) => state.client?.app_url,
  available_languages: (state) => state.client?.available_languages?.split(',') ?? [],
  available_modules: (state) => state.client?.available_modules?.split(',') ?? [],
  hasModule: state => module => {
    const modules = state.client?.available_modules?.split(',') ?? [];

    return modules.includes(module);
  },
  available_seats: (state) => state.client?.available_seats,
  client_name: (state) => {
    if (!state.client?.client_name) {
      return '';
    }

    return state.client?.client_name;
  },
  available_features: state => state?.client?.available_features.split(',') ?? [],
  hasFeature: state => feature => {
    const features = state?.client?.available_features?.split(',') ?? [];

    return features.includes(feature);
  },
  available_library_contents: (state) => state.client?.available_library_contents?.split(',') ?? [],
  hasLibraryContent: state => library => {
    const libraryContents = state?.client?.available_library_contents?.split(',') ?? [];

    return libraryContents.includes(library);
  },
  available_libraries: (state) => state.client?.available_libraries?.split(',') ?? [],
  hasLibrary: state => library => {
    const libraries = state?.client?.available_libraries?.split(',') ?? [];

    return libraries.includes(library);
  },
  default_language: (state) => state.client?.default_language,
  enable_ai: (state) => state.client?.enable_ai === true,
  export_formats: (state) => state.client?.export_formats?.split(',') ?? [],
  header_background: (state) => state.client?.header_background,
  header_foreground: (state) => state.client?.header_foreground,
  logo: (state) => state.client?.logo,
  site_admin_email: (state) => state.client?.site_admin_email,
  sms_enabled: (state) => state.client?.sms_enabled === true,
  sso_enabled: (state) => state.client?.sso_enabled === true,
  sso_authority: (state) => state.client?.sso_authority,
  sso_authority_url: (state) => state.client?.sso_authority_url,
  sso_client_id: (state) => state.client?.sso_client_id,
  sso_public_key_url: (state) => state.client?.sso_public_key_url,
  timezone: (state) => state.client?.timezone,
  two_fa_setting: (state) => state.client?.two_fa_setting,
  client_handle: () => {
    const [env] = window?.location?.hostname?.split('.') ?? [];

    const envs = {
      localhost: 'dev',
      devapp: 'dev',
      stagingapp: 'stagingapi'
    };

    return envs[env] ?? env;
  },
  hasSiteUpdates: state => state.hasSiteUpdates,
  activeLibraries: state => state.activeLibraries,
  ats_jobs_name: state => state.client?.ats_jobs_name ?? null,
  ats_interviews_name: state => state.client?.ats_interviews_name ?? null,
  ai_engine: state => state.client?.ai_engine ?? null,
  settings_saved_at: state => state.client?.settings_saved_at ?? null
};

const actions = {
  async getClient (context, { router, isUpdate = false }) {
    try {
      let manifest;

      if (window.MANIFEST && !isUpdate) {
        manifest = JSON.parse(window.MANIFEST);

        if (manifest === null || manifest === undefined) {
          context.dispatch('user/clearUser', {}, { root: true });
          router.push({ name: 'error' });
          return;
        }
      } else {
        const baseURL = context.getters?.api_url || 'https://dev.quinto.work/api';
        const response = await axios(`${baseURL}/frontend-bootstrap-data`);

        if (response?.data === 'Error.') {
          context.dispatch('user/clearUser', {}, { root: true });
          router.push({ name: 'error' });
          return;
        }

        manifest = JSON.parse(response?.data?.payload?.data.manifest);

        const overwrittenStrings = JSON.parse(response?.data?.payload?.data?.overwritten_strings);
        Object.keys(overwrittenStrings).forEach(language => {
          i18n.global.mergeLocaleMessage(language, overwrittenStrings[language]);
        });
      }

      context.commit('SET_CLIENT', manifest);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
  setClient (context, client) {
    context.commit('SET_CLIENT', client);
  },
  setSiteUpdates (context, value) {
    context.commit('SET_SITE_UPDATES', value);
    localStorage.setItem('hasSiteUpdates', value);
  },

  async updateActiveLibraries (context) {
    const clientLibraries = context.rootGetters['client/available_libraries'];

    const libraries = clientLibraries
      .map(text => {
        const key = text.toLowerCase() === 'additional_info' ? 'additional_information' : text.toLowerCase();

        const languageKeyValue = {
          CERTIFICATIONS: 'CERTIFICATION',
          EDUCATIONS: 'EDUCATION',
          EXPERIENCES: 'EXPERIENCE'
        }[text] || text;

        return {
          enabled: 'No',
          key: `${key}_enabled`,
          text: t(text.toLowerCase(), 2),
          languageKey: languageKeyValue,
          contentType: text === 'ADDITIONAL_INFO' ? 'ADDITIONAL_INFORMATION' : text
        };
      })
      .sort((a, b) => a?.text.localeCompare(b?.text));

    const response = await context.dispatch('api/request', {
      endpoint: 'platform.application.content-defaults.index'
    }, { root: true });

    const getContentDefaultsSettings = response?.payload?.data ?? [];

    const activeLibraries = libraries.reduce((filtered, option) => {
      if (getContentDefaultsSettings[option?.key] === 'Yes') {
        filtered.push(option);
      }

      return filtered;
    }, []);

    context.commit('SET_ACTIVE_LIBRARIES', activeLibraries);
  }
};

const mutations = {
  SET_CLIENT (state, value) {
    state.client = value;
    state.hasLoaded = true;
  },
  SET_SITE_UPDATES (state, value) {
    state.hasSiteUpdates = value;
  },
  SET_ACTIVE_LIBRARIES (state, value) {
    state.activeLibraries = value;
    localStorage.setItem('client_active_libraries', JSON.stringify(value));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import CRUDService from '@/services/hrsg/CRUDService';

class NotificationService extends CRUDService {
  constructor () {
    super('platform.notifications');
  }

  async seen () {
    return await this.request({
      endpoint: `${this.endpoint}.seen`
    });
  }

  async readAll (data) {
    return await this.request({
      endpoint: `${this.endpoint}.read-all`,
      data
    });
  }

  async toggleRead (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.toggle-read`,
      pathParams: { id },
      data
    });
  }

  async getJobStatus (ids) {
    const response = await this.request({
      endpoint: `${this.endpoint}.job-status`,
      pathParams: { ids }
    });

    return response?.payload?.data ?? {};
  }
}

export default new NotificationService();

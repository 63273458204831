// Taken from https://www.notion.so/hrsg/Tags-ea3c1f2b3268432a90907f279d0ce584
export default {
  'light-grey': {
    backgroundColor: '#F0F0F0',
    color: '#333333',
    colorClass: 'text-grey-1b',
    backgroundColorClass: 'bg-light-grey-3'
  },
  grey: {
    backgroundColor: '#E2E2E2',
    color: '#333333',
    colorClass: 'text-grey-1b',
    backgroundColorClass: 'bg-light-grey-4'
  },
  brown: {
    backgroundColor: '#ECDBD4',
    color: '#452519',
    colorClass: 'text-brown-00',
    backgroundColorClass: 'bg-light-brown-1'
  },
  orange: {
    backgroundColor: '#FFE9C7',
    color: '#593600',
    colorClass: 'text-orange-00',
    backgroundColorClass: 'bg-light-orange-1'
  },
  yellow: {
    backgroundColor: '#FFF8BD',
    color: '#494200',
    colorClass: 'text-yellow-00',
    backgroundColorClass: 'bg-light-yellow-1'
  },
  green: {
    backgroundColor: '#DDF5DD',
    color: '#274726',
    colorClass: 'text-green-00',
    backgroundColorClass: 'bg-light-green-1'
  },
  cyan: {
    backgroundColor: '#D3F6F9',
    color: '#0F4448',
    colorClass: 'text-cyan-0',
    backgroundColorClass: 'bg-light-cyan-1'
  },
  blue: {
    backgroundColor: '#DBF2FF',
    color: '#133959',
    colorClass: 'text-blue-0',
    backgroundColorClass: 'bg-light-blue-1'
  },
  purple: {
    backgroundColor: '#E5E5FE',
    color: '#3B3C4B',
    colorClass: 'text-purple-00',
    backgroundColorClass: 'bg-light-purple-1'
  },
  magenta: {
    backgroundColor: '#FEE2FF',
    color: '#503551',
    colorClass: 'text-magenta-00',
    backgroundColorClass: 'bg-light-magenta-1'
  },
  red: {
    backgroundColor: '#FFE1E1',
    color: '#6A1919',
    colorClass: 'text-red-00',
    backgroundColorClass: 'bg-light-red-1'
  }
};

import router from '@/router/index.js';
export default {
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted (el, binding, vnode, prevVnode) {
    el.addEventListener('click', function () {
      if (binding.value) {
        if (router.hasRoute(binding.value)) {
          router.push({ name: binding.value });
        }
      }
    });
  }
};

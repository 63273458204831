<template>
  <div
    aria-live="assertive"
    class="toast z-[100] pointer-events-none absolute flex items-end px-4 py-6 sm:items-start sm:p-6"
    :style="{
      'top': `${1.75 + (index * 5.5)}rem`,
      'right': '0px',
      'left': '0px',
      'bottom': '0px'
    }"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        appear
        name="toast"
        tag="div"
      >
        <div
          :class="{ 'transition ease-in toast-leave-to': toastAutoCloseAnimation }"
          class="pointer-events-auto w-full max-w-[22rem] inner overflow-hidden rounded-lg bg-white shadow-lg"
          @mouseover="$emit('mouseover')"
          @mouseout="$emit('mouseout')"
        >
          <div class="p-5 max-w-[23rem]">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <div
                  v-if="isStatus(STATUSES.Success)"
                  class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 bg-green-100 rounded-lg"
                >
                  <VIcon
                    icon="done"
                    color="text-green-2"
                    class="icon-filled"
                  />
                </div>

                <div
                  v-if="isStatus(STATUSES.Failure)"
                  class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 bg-light-red-1 rounded-lg"
                >
                  <VIcon
                    icon="close"
                    color="text-red-2"
                    class="icon-filled"
                  />
                </div>

                <div
                  v-if="isStatus(STATUSES.Warning)"
                  class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 bg-light-orange-1 rounded-lg"
                >
                  <VIcon
                    icon="warning"
                    color="text-orange-2"
                    class="icon-filled"
                  />
                </div>

                <div
                  v-if="isStatus(STATUSES.Info)"
                  class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 bg-light-blue-1 rounded-lg"
                >
                  <VIcon
                    icon="sms"
                    color="text-blue-4"
                    class="icon-filled"
                  />
                </div>
              </div>

              <div class="ml-3.5 w-0 flex-1 pt-0.5">
                <p
                  class="text-md font-normal text-grey-1"
                  v-html="message"
                />
              </div>

              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="close"
                >
                  <XIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid';
import VIcon from '@/components/VIcon';
import { computed } from 'vue';
import store from '@/store';

const STATUSES = [
  'Success',
  'Failure',
  'Warning',
  'Info'
].reduce((acc, cv) => ({ ...acc, [cv]: cv }), {});

export default {
  components: {
    XIcon,
    VIcon
  },
  props: {
    status: {
      type: String,
      default: '',
      validator: value => Object.keys(STATUSES).includes(value)
    },
    message: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    id: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'close',
    'mouseover',
    'mouseout'
  ],
  setup (props, context) {
    // Computed
    const toastAutoCloseAnimation = computed(() => store.getters['toast/getClosing'](props.id));

    // Methods
    const close = () => {
      context.emit('close');
    };

    const isStatus = status => props.status === status;

    return {
      STATUSES,
      isStatus,
      close,
      toastAutoCloseAnimation
    };
  }
};
</script>

<style scoped>
.toast-enter-from {
  opacity: 0;
  transform: translateX(0);
}
.toast-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.toast-enter-active {
  transition: all 0.6s ease;
}
.toast-leave-from {
  transform: translateX(0);
}
.toast-leave-to {
  transform: translateX(0);
  animation:fade-in 0.6s;
}
@keyframes fade-in {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    :enabled="task?.status !== 'Cancelled'"
    @click="onClickRedirect"
  >
    <template
      v-if="task?.job_status !== ALL_OPTIONS.PUBLISHED.value"
      #completed
    >
      <VDropdown
        :options="completedOptions"
        :label="$t('done')"
        icon-class="!ml-0"
        class="mr-0.5"
        button-class="text-grey-1 text-xs font-normal"
      />
    </template>

    <template #open>
      <VButtonGroup
        class="task-btn btn-sm rounded-full"
        :class="btnSize"
        :label="$t('view')"
        fixed
        :options="options"
        button-class="rounded-l-full"
        :arrow-button-class="{ 'h-[26px]' : btnSize !== 'btn-md', 'rounded-r-full' : true }"
        @click="onClickRedirect"
      />
    </template>
  </VTask>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButtonGroup from '@/components/VButtonGroup';
import VDropdown from '@/components/VDropdown';
// Constants
import ALL_OPTIONS from '@/constants/options';

export default {
  components: {
    VTask,
    VButtonGroup,
    VDropdown
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  emits: ['update'],
  setup (props, context) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const options = [
      {
        text: t('mark_as_done'),
        value: 'mark_as_done',
        click: () => {
          context.emit('update', {
            id: props.task?.id,
            data: {
              status: 'Completed'
            }
          });
        }
      }
    ];

    const completedOptions = [
      {
        text: t('reopen'),
        value: 'reopen',
        click: () => {
          context.emit('update', {
            id: props.task?.id,
            data: {
              status: 'Open'
            }
          });
        }
      }
    ];

    // Methods
    const onClickRedirect = () => {
      let name = 'content-jobs-id-edit';
      if ([ALL_OPTIONS.PUBLISHED.value, ALL_OPTIONS.DEPRECATED.value].includes(props.task?.job_status) && props.task?.status === ALL_OPTIONS.COMPLETED.value) {
        name = 'content-jobs-id-description';
      }

      router.push({
        name,
        params: {
          id: props.task?.taskable_id
        }
      });
    };

    return {
      ALL_OPTIONS,
      completedOptions,
      options,
      onClickRedirect
    };
  }
};
</script>

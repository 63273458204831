import AuthenticationService from '@/services/hrsg/platform/AuthenticationService';

const getValue = (state, field) => state.personify?.[field] || state.user?.[field];

const state = () => {
  return {
    personify: {},
    user: {},
    open_tasks_count: 0,
    unseen_notifications_count: 0,
    table_data_per_page: 25,
    content_defaults: {}
  };
};

const getters = {
  open_tasks_count: state => state.open_tasks_count,
  unseen_notifications_count: state => state.unseen_notifications_count,
  isLoggedIn: state => !!getValue(state, 'api_token'),
  language: state => getValue(state, 'language'),
  hasPermission: state => permission => getValue(state, 'permissions')?.some(p => p === permission) ?? false,
  personify: state => state.personify,
  user: state => {
    if (Object.keys(state.personify).length > 0) {
      return state.personify;
    }

    return state.user;
  },
  current_user: state => state.user,
  token: state => getValue(state, 'api_token'),
  table_data_per_page: state => state.table_data_per_page,
  content_defaults: state => state.content_defaults,
  ai_suggestions: state => state.content_defaults?.ai_suggestions_enabled
};

const actions = {
  async setUser (context, value) {
    if (!value) return;

    context.commit('SET_USER', value);
  },
  async setPermissions (context) {
    const response = await context.dispatch('api/request', {
      endpoint: 'platform.my-account.permissions'
    }, { root: true });

    if (Object.entries(context?.state?.personify ?? {}).length > 0) {
      context.commit('SET_PERSONIFY_PERMISSIONS', response?.payload?.data ?? []);
      return;
    }

    context.commit('SET_USER_PERMISSIONS', response?.payload?.data ?? []);
  },
  setTasksCount (context, value) {
    context.commit('SET_TASKS_COUNT', value);
  },
  clearUser ({ commit }) {
    commit('CLEAR_USER');
    localStorage.removeItem('pending_job_updates_was_shown');
    localStorage.removeItem('table_data_per_page');
  },
  async logout (context) {
    const channels = Object.keys(window.Echo?.connector?.channels ?? {});
    channels.forEach(channel => window.Echo.leave(channel));

    await AuthenticationService.logout();
    context.commit('CLEAR_USER');
    context.commit('CLEAR_PERSONIFY_USER');
    sessionStorage.clear();

    const url = context.rootGetters['client/after_logout_url'];
    if (url) {
      window.location.href = url;
      return;
    }

    const router = context.rootGetters['api/router'];
    router.replace({ name: 'login' });
  },
  setPersonifyUser ({ commit }, value) {
    if (!value) return;

    commit('SET_PERSONIFY_USER', value);
  },
  async clearPersonifyUser (context) {
    context.commit('CLEAR_PERSONIFY_USER');
    await context.dispatch('setPermissions');
  },
  updateTablesColumns ({ commit }, value) {
    commit('UPDATE_TABLE_COLUMN', value);
  },
  setTableDataPerPage ({ commit }, value) {
    if (!value) return;
    commit('SET_TABLE_DATA_PER_PAGE', value);
  },
  setNotificationsCount (context, value) {
    context.commit('SET_NOTIFICATIONS_COUNT', value);
  },
  setAiSuggestions (context, value) {
    if (!value) return;
    const contentDefaults = { ...context.state.content_defaults, ai_suggestions_enabled: value };
    context.commit('SET_CONTENT_DEFAULT', contentDefaults);
  },
  setContentDefaults (context, value) {
    if (!value) return;
    context.commit('SET_CONTENT_DEFAULT', value);
  },
  updateUserPositions ({ commit }, positions) {
    commit('SET_USER', { positions });
  }
};

const mutations = {
  SET_TASKS_COUNT (state, value) {
    state.open_tasks_count = value;
    localStorage.setItem('open_tasks_count', value);
  },
  SET_USER (state, value) {
    const user = { ...state.user, ...value };
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  SET_USER_PERMISSIONS (state, value) {
    if (!state?.user?.permissions) return;

    state.user.permissions = value;
    localStorage.setItem('user', JSON.stringify(state.user));
  },
  CLEAR_USER (state) {
    state.user = {};
    localStorage.removeItem('user');
  },
  SET_PERSONIFY_USER (state, value) {
    state.personify = value;
    localStorage.setItem('personify', JSON.stringify(value));
    sessionStorage.removeItem('myTeamPresetPosition');
  },
  SET_PERSONIFY_PERMISSIONS (state, value) {
    if (!state?.personify?.permissions) return;

    state.personify.permissions = value;
    localStorage.setItem('personify', JSON.stringify(state.personify));
  },
  CLEAR_PERSONIFY_USER (state) {
    state.personify = {};
    localStorage.removeItem('personify');
    sessionStorage.removeItem('myTeamPresetPosition');
  },
  UPDATE_TABLE_COLUMN (state, value) {
    if (Object.keys(state.personify).length > 0) {
      state.personify.tables_columns[value.tableKey] = value.data;
    } else {
      state.user.tables_columns[value.tableKey] = value.data;
    }

    localStorage.setItem('user', JSON.stringify(state.user));
  },
  SET_TABLE_DATA_PER_PAGE (state, value) {
    state.table_data_per_page = value;
    localStorage.setItem('table_data_per_page', value);
  },
  SET_NOTIFICATIONS_COUNT (state, value) {
    state.unseen_notifications_count = value;
    localStorage.setItem('unseen_notifications_count', value);
  },
  SET_CONTENT_DEFAULT (state, value) {
    state.content_defaults = value;
    localStorage.setItem('content_defaults', JSON.stringify(value));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

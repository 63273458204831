export default function hasFeature (...features) {
  return ({ next, store, nextVue }) => {
    const isAuthorized = features.some(feature => store.getters['client/hasFeature'](feature));

    if (!isAuthorized) {
      return nextVue({ name: 'error' });
    }

    return next();
  };
}

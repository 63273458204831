<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    enabled
    @click="onClick"
  >
    <template #default>
      <span>
        {{ $t('task_opening_recruiter', { assigner: $h.getFullName(task?.assigned_by) }) }}
      </span>
    </template>

    <template #open>
      <VButton
        class="btn secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg round"
        :class="btnSize"
        @click="onClick"
      >
        {{ $t('open') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';

export default {
  components: {
    VTask,
    VButton
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // Misc
    const router = useRouter();

    // Methods
    const onClick = () => {
      router.push({
        name: 'content-interviews-openings-id',
        params: {
          id: props.task?.taskable_id
        }
      });
    };

    return {
      onClick
    };
  }
};
</script>

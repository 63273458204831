import ServiceUtil from '@/services/hrsg/ServiceUtil';

export default class CRUDService extends ServiceUtil {
  endpoint = null;
  defaultPathvars = {};

  /**
   * @param { String } endpoint
   */
  constructor (endpoint) {
    super();
    this.endpoint = endpoint;
    this.verifyEndPointField();
  }

  async index (queryParams = {}, payload = {}, fullResponse = false) {
    const response = await this.request({
      endpoint: `${this.endpoint}.index`,
      pathParams: this.getPathvars(),
      queryParams,
      ...payload
    });

    return fullResponse ? response : this.getArrayResponse(response);
  }

  /**
   * Deletes an item
   * @param { Number } id
   * @param { Object } data
   */
  async delete (id, data = {}) {
    if (!id) {
      throw new Error('ID parameter is required');
    }

    return await this.request({
      endpoint: `${this.endpoint}.delete`,
      pathParams: this.getPathvars({ id }),
      data
    });
  }

  /**
   * Reads an item
   * @param { String } id
   * @param { Array } relationships
   * @param { Boolean } multiple
   */
  async read (id, relationships = [], multiple = false, filter = [], queryParams = {}) {
    if (!id) {
      throw new Error('ID parameter is required');
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.show`,
      pathParams: this.getPathvars({ id }),
      queryParams: {
        ...queryParams,
        ro_r: relationships,
        ro_f: JSON.stringify(filter)
      }
    });

    return multiple ? this.getArrayResponse(response) : this.getFirstItem(response);
  }

  /**
   * Creates an item
   * @param { Object } data
   * @param { Array } relationships
   */
  async create (data, relationships = [], queryParams = {}) {
    if (!data) {
      throw new Error('Data parameter is required');
    }

    return await this.request({
      endpoint: `${this.endpoint}.store`,
      pathParams: this.getPathvars(),
      queryParams: {
        ...queryParams,
        ro_r: relationships
      },
      data
    });
  }

  /**
   * Updates an item
   * @param { String } id
   * @param { Object } data
   * @param { Array } relationships
   */
  async update (id, data, relationships = [], queryParams = {}) {
    if (!id || !data) {
      throw new Error('id/data parameter is required');
    }

    return await this.request({
      endpoint: `${this.endpoint}.update`,
      pathParams: this.getPathvars({ id }),
      queryParams: {
        ...queryParams,
        ro_r: relationships
      },
      data
    });
  }

  /**
   * Assures that the endpoint field is not empty
   */
  verifyEndPointField () {
    if (!this.endpoint) {
      throw new Error('Endpoint required.');
    }
  }

  /**
   * Default pathvars
   */
  getPathvars (addend = {}) {
    return { ...this.defaultPathvars, ...addend };
  }

  /**
   * Sets default pathvars
   */
  setPathvars (vars = {}) {
    this.defaultPathvars = { ...this.defaultPathvars, ...vars };
  }

  /**
   * Returns the endpoint field to be used by other endpoints
   */
  getEndpoint () {
    return this.endpoint;
  }
}

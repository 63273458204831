<template>
  <div>
    <VNotification
      :item="notification"
      @toggle-read="$emit('toggle-read',notification)"
      @close="$emit('close')"
    >
      <template #header>
        <span>
          {{ $t('export_ready_to_download') }}
        </span>
      </template>
      <template #type>
        <span class="cursor-default">
          {{ $t('download') }} :
        </span>
        <span @click="onClickDownload">
          {{ notification.data?.fileName }}
        </span>
      </template>
    </VNotification>
  </div>
</template>

<script>
// Components
import VNotification from '@/components/templates/notifications/VNotification';
// Services
import JobService from '@/services/hrsg/content/jobs/JobService';

export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props) {
    const onClickDownload = async () => {
      await JobService.download(props.notification.data?.fileId);
    };

    return {
      onClickDownload
    };
  }

};
</script>

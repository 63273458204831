<template>
  <div
    v-if="showLabel"
    class="mt-1 rounded-xl px-2.5 py-px flex items-center"
    :class="[bgColor, textColor]"
  >
    <div
      class="rounded-full h-2 w-2 mr-1 -ml-1"
      :class="[dotColor, borderColor, overwriteClass]"
    />

    {{ label || displayedStatus }}
  </div>

  <div
    v-else
    class="rounded-full h-2 w-2 mr-3 mt-1"
    :class="[dotColor, borderColor, overwriteClass]"
  />
</template>

<script>
import { computed } from 'vue';
// Composables
import useOptions from '@/composables/useOptions';
// Constants
import statusesColors from '@/constants/statusesColors';
import tagsColors from '@/constants/tagsColors';

export default {
  props: {
    status: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    showRing: {
      type: Boolean,
      default: false
    },
    overwriteClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '',
      validator: value => [
        'green',
        'orange',
        'red',
        'purple',
        'grey',
        'blue',
        ''
      ].includes(value)
    }
  },
  setup (props) {
    // Composables
    const { allOptions } = useOptions();

    // Constants
    const DOT_COLORS = {
      green: 'bg-green-4',
      grey: 'bg-grey-6',
      red: 'bg-red-3',
      orange: 'bg-orange-3',
      purple: 'bg-purple-3',
      blue: 'bg-blue-6'
    };

    const RING_COLORS = {
      green: 'border-green-4',
      grey: 'border-grey-6',
      red: 'border-red-3',
      orange: 'border-orange-3',
      purple: 'border-purple-3',
      blue: 'border-blue-6'
    };

    // Computed
    const statusColor = computed(() => props.color || statusesColors[props.status]);
    const tagColor = computed(() => tagsColors[statusColor.value]);
    const textColor = computed(() => tagColor.value?.colorClass ?? 'text-gray-100');
    const dotColor = computed(() => props.showRing ? 'bg-transparent' : DOT_COLORS[statusColor.value]);
    const borderColor = computed(() => `border-2 ${RING_COLORS[statusColor.value]}`);

    const bgColor = computed(() => {
      if (props.showRing && !props.showLabel) return 'bg-transparent';

      return tagColor.value?.backgroundColorClass ?? 'bg-gray-100';
    });

    const displayedStatus = computed(() => {
      const option = Object.values(allOptions.value).find(values => values.value === props.status);

      return option?.text ?? '';
    });

    return {
      statusColor,
      displayedStatus,
      borderColor,
      dotColor,
      bgColor,
      textColor
    };
  }
};
</script>

<template>
  <div
    class="absolute inset-0 flex items-center flex-col h-full"
    :class="[
      centered ? 'justify-center' : 'pt-4',
      opaque ? 'bg-white' : 'bg-white/[0.3]'
    ]"
  >
    <div
      v-if="!hideLoader"
      class="relative"
      :class="{ 'top-60': !centered }"
    >
      <div class="flex justify-center">
        <VLoader
          class="w-8 h-8 text-blue-4"
          :class="loaderClass"
          :stroke-width="4"
        />
      </div>

      <div class="mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VLoader from '@/components/VLoader';

export default {
  components: {
    VLoader
  },
  props: {
    centered: {
      type: Boolean,
      default: false
    },
    hideLoader: {
      type: Boolean,
      default: false
    },
    opaque: {
      type: Boolean,
      default: false
    },
    loaderClass: {
      type: String,
      default: ''
    }
  }
};
</script>

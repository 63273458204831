export default function useDownload () {
  const downloadFile = (response) => {
    if (!response) return;

    const contentType = response?.headers?.['content-type'];
    const filename = response?.headers?.['content-disposition']?.split('"')?.[1] ?? '';

    const blob = new Blob([response?.data], { type: contentType });
    const url = URL.createObjectURL(blob);

    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return {
    downloadFile
  };
}

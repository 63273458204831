<template>
  <VNotification
    :item="notification"
    :name="notification.data?.status === ALL_OPTIONS.CLOSED.value ? 'content-jobs-id-description' : 'content-jobs-id-edit'"
    @toggle-read="$emit('toggle-read', notification)"
    @close="$emit('close')"
  >
    <template #header>
      {{ notification.data?.updatedByName }}

      <span v-if="notification.data?.status === ALL_OPTIONS.CLOSED.value">
        {{ $t('notification_closed_a_job_to_feedback') }}
      </span>

      <span v-else>
        {{ $t('notification_re_opened_a_job_for_feedback') }}
      </span>
    </template>

    <template #type>
      {{ $t('notification_job_collaboration', { job: notification.data?.jobName }) }}
    </template>
  </VNotification>
</template>

<script>
// Components
import VNotification from '@/components/templates/notifications/VNotification';
// Constants
import ALL_OPTIONS from '@/constants/options';
export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup () {
    return { ALL_OPTIONS };
  }
};
</script>

<template>
  <div class="relative z-30">
    <div
      v-if="show"
      v-onclickaway="{ handler: onClickAway, include }"
      class="absolute top-5 rounded-lg bg-white shadow-floating"
      :class="containerClass"
    >
      <div
        v-if="$slots.tabs"
        class="border-b px-5"
      >
        <div
          v-if="manualCloseButton"
          class="grid grid-cols-[auto,30px] items-center"
        >
          <slot name="tabs" />
          <VIcon
            icon="close"
            weight="font-semibold"
            color="text-grey-3"
            class="text-end cursor-pointer"
            @click="$emit('hide')"
          />
        </div>

        <slot
          v-else
          name="tabs"
        />
      </div>

      <div
        class="pl-5 pr-3 py-4"
        :class="[contentClass]"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
// Components
import VIcon from '@/components/VIcon';

export default {
  components: {
    VIcon
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    include: {
      type: Array,
      default: () => []
    },
    manualCloseButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide'],
  setup (props, context) {
    // Data
    const isEmitEnabled = ref(false);

    // Methods
    const onClickAway = () => {
      if (props.manualCloseButton) return;
      if (!isEmitEnabled.value) return;

      context.emit('hide');
    };

    // Watchers
    watch(() => props.show, newValue => {
      if (!newValue) return;

      setTimeout(() => {
        isEmitEnabled.value = newValue;
      }, 500);
    }, {
      immediate: true
    });

    return {
      onClickAway
    };
  }
};
</script>

import CRUDService from '@/services/hrsg/CRUDService';
import useDownload from '@/composables/useDownload';
const { downloadFile } = useDownload();

class GuideService extends CRUDService {
  constructor () {
    super('content.interview-guides');
  }

  async duplicate (id) {
    return await this.request({
      endpoint: `${this.endpoint}.duplicate`,
      pathParams: { id }
    });
  }

  async saveAsTemplate (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.save-as-template`,
      pathParams: { id },
      data
    });
  }

  async pdf (id, queryParams = {}) {
    const response = await this.request({
      endpoint: `${this.endpoint}.pdf`,
      pathParams: { id },
      queryParams,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async docx (id, queryParams = {}) {
    const response = await this.request({
      endpoint: `${this.endpoint}.docx`,
      pathParams: { id },
      queryParams,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async publish (id) {
    return await this.request({
      endpoint: `${this.endpoint}.publish`,
      pathParams: { id }
    });
  }

  async usedQuestions (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.used-question-ids`,
      pathParams: { id }
    });

    return this.getArrayResponse(response);
  }
}

export default new GuideService();

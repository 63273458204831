<template>
  <div class="min-w-full">
    <Disclosure
      v-slot="{ open }"
      as="nav"
      class="bg-blue-10"
    >
      <div class="px-6">
        <div class="flex items-center justify-between h-14">
          <div class="flex items-center">
            <div class="flex items-center flex-shrink-0">
              <router-link
                class="cursor-pointer"
                :to="{ name: 'home' }"
              >
                <img
                  class="h-6.5 w-6.5 mr-3"
                  :src="require('@/assets/Quinto_logo_q_white.svg')"
                >
              </router-link>
            </div>

            <div class="hidden md:block w-100">
              <div class="ml-7.5 flex items-baseline space-x-4">
                <template
                  v-for="item in navigationItems"
                  :key="item.name"
                >
                  <router-link
                    v-if="!item.children"
                    :to="{ name: item.name }"
                    :class="[isActive(item.name) ? 'bg-blue-1' : 'hover:bg-blue-1', 'text-white hover:text-white px-2 py-1.25 rounded text-sm']"
                    :aria-current="isActive(item.name) ? 'page' : undefined"
                  >
                    {{ item.title }}
                  </router-link>

                  <VDropdown
                    v-else
                    :button-class="[isActive(item.name) ? 'bg-blue-1' : 'hover:bg-blue-1 hover:text-white', 'text-white px-2 py-1.25 rounded text-sm']"
                    :options="formatChildren(item.children)"
                  >
                    {{ item.title }}
                  </VDropdown>
                </template>
              </div>
            </div>
          </div>

          <div class="flex items-center">
            <div class="w-32 hidden md:block text-right">
              <h2 class="text-white font-medium">
                {{ $t('warehouse') }}
              </h2>
            </div>

            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">
                  Open main menu
                </span>

                <MenuIcon
                  v-if="!open"
                  class="block h-6 w-6"
                  aria-hidden="true"
                />

                <XIcon
                  v-else
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
              </DisclosureButton>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
import { useI18n } from 'vue-i18n';
// Composables
import usePermissions from '@/composables/usePermissions';
// Components
import VDropdown from '@/components/VDropdown';
import {
  Disclosure,
  DisclosureButton
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon
} from '@heroicons/vue/outline';

export default {
  components: {
    Disclosure,
    DisclosureButton,
    MenuIcon,
    XIcon,
    VDropdown
  },
  setup () {
    // Misc
    const route = useRoute();
    const router = useRouter();

    const { t } = useI18n();
    const { libraries, permissions, hasPermissions, hasLibraryContent } = usePermissions();

    // Computed
    const user = computed(() => store.getters['user/user']);

    const navigationItems = computed(() => [
      {
        title: t('jobs', 2),
        name: 'content-warehouse-jobs',
        visible: hasPermissions(permissions.MANAGE_JOBS) && hasLibraryContent(libraries.JOBS)
      },
      {
        title: t('libraries', 2),
        name: 'content-warehouse-libraries',
        visible: hasPermissions(permissions.MANAGE_LIBRARIES)
      }
    ].filter(x => x?.visible ?? true));

    // Methods
    const isActive = name => route.matched.some(r => r.name === name);

    const formatChildren = children => {
      return children.map(child => {
        return {
          text: child.title,
          value: child.name,
          permission: child.permission,
          click: option => {
            router.push({ name: option.value });
          }
        };
      });
    };

    return {
      user,
      navigationItems,
      isActive,
      formatChildren
    };
  }
};
</script>

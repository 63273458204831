import CRUDService from '@/services/hrsg/CRUDService';
import useDownload from '@/composables/useDownload';
const { downloadFile } = useDownload();

class UserService extends CRUDService {
  constructor () {
    super('administration.users');
  }

  async anonymize (id) {
    return await this.request({
      endpoint: `${this.endpoint}.anonymize`,
      pathParams: { id }
    });
  }

  async restore (id) {
    return await this.request({
      endpoint: `${this.endpoint}.restore`,
      pathParams: { id }
    });
  }

  async personify (id) {
    return await this.request({
      endpoint: `${this.endpoint}.personify`,
      pathParams: { id }
    });
  }

  async endPersonify (id) {
    return await this.request({
      endpoint: `${this.endpoint}.end-personify`,
      pathParams: { id }
    });
  }

  async resetPassword (id) {
    return await this.request({
      endpoint: `${this.endpoint}.reset-password`,
      pathParams: { id }
    });
  }

  async invite (id) {
    return await this.request({
      endpoint: `${this.endpoint}.invite`,
      pathParams: { id }
    });
  }

  async checkPositions (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.positions-check`,
      data
    });

    return response?.payload?.data?.some_active ?? false;
  }

  async assignPermissionSets (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.assign-permission-sets`,
      pathParams: { id },
      data
    });
  }

  async setStatus (data) {
    return await this.request({
      endpoint: `${this.endpoint}.set-status`,
      data
    });
  }

  async setType (data) {
    return await this.request({
      endpoint: `${this.endpoint}.set-type`,
      data
    });
  }

  async checkLicense (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.license-availability-check`,
      data
    });

    return response?.payload?.data?.all_available;
  }

  async export (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.export-data`,
      pathParams: { id },
      queryParams: { ro_x: true },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }
}

export default new UserService();

<template>
  <VNotification
    :item="notification"
    :type-class="isClickable ? '!cursor-pointer' : '!cursor-defalut'"
    @toggle-read="$emit('toggle-read',notification)"
    @close="$emit('close')"
  >
    <template #avatar>
      <VAvatar
        v-if="userAvatar"
        :user="userAvatar"
        size="medium"
        class="flex-none mr-3 mt-0.75"
      />
      <div
        v-else
        class="w-8 mr-3 shrink-0"
      />
    </template>

    <template #header>
      <span>
        {{ $t(`notification_${notification.type}`) }}
      </span>
    </template>

    <template #type>
      <span @click="onClick">
        {{ notification.data?.openingName }} - {{ candidateName }}
      </span>
    </template>
  </VNotification>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { helpers } from '@/utils/helpers';
// Components
import VAvatar from '@/components/VAvatar.vue';
import VNotification from '@/components/templates/notifications/VNotification';

export default {
  components: {
    VNotification,
    VAvatar
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props, context) {
    // Misc
    const router = useRouter();

    // Computed
    const isClickable = computed(() => {
      return !!props?.notification?.data?.openingId;
    });

    const candidateName = computed(() => typeof props.notification.data.candidate === 'string' ? props.notification.data.candidate : helpers.getFullName(props.notification.data?.candidate));
    const userAvatar = computed(() => {
      if (!props?.notification?.data?.candidate || props?.notification?.data?.candidate === '') return null;

      const { firstname, lastname } = props.notification.data.candidate;
      return {
        firstname: firstname || candidateName.value,
        lastname
      };
    });

    // Methods
    const onClick = () => {
      if (!isClickable.value) return;

      router.push({
        name: 'content-interviews-openings-id-candidates',
        params: {
          id: props.notification?.data?.openingId,
          candidateId: props.notification?.data?.candidacyId
        }
      });

      context.emit('close');
    };

    return {
      candidateName,
      userAvatar,
      isClickable,
      onClick
    };
  }
};
</script>

const state = () => {
  return {
    breadcrumbs: {}
  };
};

const getters = {
  getName: state => name => state.breadcrumbs[name]
};

const actions = {
  setName (context, data) {
    context.commit('SET_NAME', data);
  }
};

const mutations = {
  SET_NAME (state, data) {
    state.breadcrumbs[data.key] = data.value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import LibraryService from '@/services/hrsg/content/libraries/LibraryService';

class SkillService extends LibraryService {
  constructor () {
    super('content.skills');
  }

  async jobComparisons (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.job-comparisons`,
      pathParams: { id }
    });

    return response?.payload?.data ?? [];
  }
}

export default new SkillService();

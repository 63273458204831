export default {
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted (el, binding) {
    el.onClickAway = event => {
      if (!(binding.value?.handler instanceof Function)) return;

      const elements = binding.value?.include ?? [];
      elements.push(el);

      if (!elements.some(el => el?.contains(event.target))) {
        binding.value?.handler(event);
      }
    };

    if (!el || !el.getRootNode() || !el.getRootNode().body) {
      return;
    }

    el.getRootNode().body.addEventListener('mousedown', el.onClickAway);
  },
  // called when the parent component is unmounted
  unmounted (el) {
    document.body.removeEventListener('mousedown', el.onClickAway);
  }
};

import { ref } from 'vue';
import store from '@/store';

export default function useRequest () {
  // Loading state
  const isLoading = ref(false);

  // Methods
  const request = async config => {
    isLoading.value = true;

    const response = await store.dispatch('api/request', config);

    isLoading.value = response === 'request-canceled';

    if (response === 'request-canceled') return {};
    return response;
  };

  const isSuccessResponse = response => {
    return response?.payload?.message?.status === 'Success';
  };

  const isFailureResponse = response => {
    return response?.payload?.message?.status === 'Failure';
  };

  const getArrayResponse = response => {
    return response?.payload?.data ?? [];
  };

  const getFirstElementResponse = response => {
    return response?.payload?.data?.[0] ?? {};
  };

  const getPagination = response => {
    const {
      current_page: page,
      total,
      per_page: perPage,
      last_page: lastPage
    } = response?.payload?.pagination ?? {};

    return {
      page,
      total,
      perPage,
      lastPage
    };
  };

  const getFilters = filters => {
    return filters
      ? JSON.stringify(filters.flat())
      : null;
  };

  const getUnauthorizedResponse = response => {
    return response?.payload?.error ?? false;
  };

  return {
    isLoading,
    request,
    getArrayResponse,
    getFirstElementResponse,
    getPagination,
    getFilters,
    isSuccessResponse,
    isFailureResponse,
    getUnauthorizedResponse
  };
}

import store from '@/store';
// Constants
import PERMISSIONS from '@/constants/permissions';
import FEATURES from '@/constants/features';
import LIBRARIES from '@/constants/libraries';
import MODULES from '@/constants/modules';

export default function useModal () {
  /**
   * Check if the user has the permission
   * @param {Array} permissions
   * @returns {Boolean}
   */
  const hasPermissions = (...permissions) => {
    return permissions.every(permission => store.getters['user/hasPermission'](permission));
  };

  /**
   * Check if the client has the feature enabled
   * @param {Array} features
   * @returns {Boolean}
   */
  const hasFeatures = (...features) => {
    return features.every(feature => store.getters['client/hasFeature'](feature));
  };

  /**
   * Check if the client has the library enabled
   * @param {Array} libraries
   * @returns {Boolean}
   */
  const hasLibraries = (...libraries) => {
    return libraries.every(library => store.getters['client/hasLibrary'](library));
  };

  /**
   * Check if the client has the library content enabled
   * @param {Array} libraries
   * @returns {Boolean}
   */
  const hasLibraryContent = (...libraries) => {
    return libraries.every(library => store.getters['client/hasLibraryContent'](library));
  };

  /**
   * Check if the client has the modules
   * @param {Array} modules
   * @returns {Boolean}
   */
  const hasModules = (...modules) => {
    return modules.every(module => store.getters['client/hasModule'](module));
  };

  return {
    features: FEATURES,
    permissions: PERMISSIONS,
    libraries: LIBRARIES,
    modules: MODULES,
    hasLibraryContent,
    hasLibraries,
    hasFeatures,
    hasPermissions,
    hasModules
  };
}

<template>
  <div class="bottom-4 right-8 fixed z-30">
    <VDropdown
      :options="options"
      divider-class="!border-solid"
      direction="wnw"
    >
      <div class="bg-white rounded-full w-11 h-11 flex items-center justify-center cursor-pointer shadow-md border border-slate-300">
        <VIcon
          icon="question_mark"
          color="text-black"
          weight="font-light"
          size="text-3xl"
        />
      </div>
    </VDropdown>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
// Components
import VIcon from '@/components/VIcon';
import VDropdown from '@/components/VDropdown';

export default {
  components: {
    VIcon,
    VDropdown
  },
  setup () {
    // Misc
    const { t } = useI18n();

    // Constants
    const ICON_SIZE = 'text-lg';

    // Computed
    const options = computed(() => [
      {
        text: t('whats_new'),
        value: 'whats_new',
        icon: {
          name: 'reviews',
          color: 'text-magenta-1',
          size: ICON_SIZE
        },
        click: () => {
          window.open('https://quinto.canny.io/changelog', '_blank');
        }
      },
      {
        text: t('help_center'),
        value: 'help_center',
        icon: {
          name: 'help',
          color: 'text-blue-3',
          size: ICON_SIZE
        },
        click: () => {
          window.open('https://help.quinto.work', '_blank');
        }
      },
      {
        text: t('quick_start'),
        value: 'quick_start',
        icon: {
          name: 'auto_fix',
          color: 'text-green-1',
          size: ICON_SIZE
        },
        click: () => {
          window.open('https://help.quinto.work/hc/quick-start', '_blank');
        }
      },
      {
        divider: true
      },
      {
        text: t('give_feedback'),
        value: 'give_feedback',
        icon: {
          name: 'emoji_objects',
          color: 'text-purple-1',
          size: ICON_SIZE
        },
        click: () => {
          window.open('https://quinto.canny.io/feature-requests', '_blank');
        }
      }
    ]);

    return {
      options
    };
  }
};

</script>

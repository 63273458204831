const state = () => {
  return {
    contentFinderSelectedLibrary: 0,
    contentFinderLibraryValue: {},
    selectedJobBuilderLibraryItem: {}
  };
};

const getters = {
  contentFinderSelectedLibrary: state => state.contentFinderSelectedLibrary,
  contentFinderLibraryValue: state => state.contentFinderLibraryValue,
  selectedJobBuilderLibraryItem: state => state.selectedJobBuilderLibraryItem
};

const actions = {
  setContentFinderSelectedLibrary (context, value) {
    context.commit('SET_CONTENT_FINDER_SELECTED_LIBRARY', value);
  },
  setContentFinderLibraryValue (context, value) {
    if (!value) return;
    context.commit('SET_CONTENT_FINDER_LIBRARY_VALUE', value);
  },
  setSelectedJobBuilderLibraryItem (context, value) {
    context.commit('SET_SELECTED_JOB_BUILDER_LIBRARY_ITEM', value);
  }
};

const mutations = {
  SET_CONTENT_FINDER_SELECTED_LIBRARY (state, value) {
    state.contentFinderSelectedLibrary = value;
  },
  SET_CONTENT_FINDER_LIBRARY_VALUE (state, value) {
    state.contentFinderLibraryValue = value;
  },
  SET_SELECTED_JOB_BUILDER_LIBRARY_ITEM (state, value) {
    state.selectedJobBuilderLibraryItem = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import { computed } from 'vue';
import store from '@/store';
import i18n from '@/i18n';
import { dayjs } from '@/utils/dayjs';

export default function useDate () {
  // Inject
  const t = i18n.global.t;

  // Computed
  const timezone = computed(() => store.getters['client/timezone']);

  // Methods
  const format = (date, format = 'MMM D, YYYY') => {
    if (!date) {
      return '';
    }

    return dayjs(date).tz(timezone.value).format(format);
  };

  const fromNow = (date, noSuffix) => {
    if (date) {
      const plugin = require('dayjs/plugin/relativeTime');
      dayjs.extend(plugin);

      return dayjs(date).fromNow(noSuffix);
    }

    return t('never');
  };

  const getDuration = (date1, date2) => {
    if (!date1 || !date2) {
      return '';
    }

    const d1 = dayjs(date1).format('YYYY-MM-DD');
    const d2 = dayjs(date2);
    const y = d2.diff(d1, 'y');

    if (y <= 0) {
      return `${d2.diff(d1, 'M')}m`;
    }

    return `${y}y ${d2.subtract(y, 'y').diff(d1, 'M')}m`;
  };

  const isDateValid = (dateString) => {
    return dayjs(dateString).isValid();
  };

  const timezoneShortCode = (date) => {
    if (date) {
      return dayjs(date).tz(timezone.value).format('z');
    }

    return '';
  };

  return {
    dayjs,
    timezone,
    format,
    fromNow,
    getDuration,
    isDateValid,
    timezoneShortCode
  };
}

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import utils from './utils';
import registerDirectives from '@/directives/register';
import Pusher from 'pusher-js';
import VueCookies from 'vue-cookies';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import './assets/fonts/google-material-symbols.css';
import './styles/app.scss';

Bugsnag.start({
  apiKey: '4df5c61362a5bd75020e7fb8fd5b3198',
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging'],
  logger: null
});

window.Pusher = Pusher;

const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App)
  .use(store)
  .use(bugsnagVue)
  .use(router)
  .use(VueCookies, { expires: '30d' })
  .use(i18n);

utils(app);
registerDirectives(app);

app.mount('#app');

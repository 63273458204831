export default {
  AI_GENERATED_COMPETENCIES: 'INTERNAL_-_AI-GENERATED_COMPETENCIES',
  ANALYTICS: 'PLUS_-_ANALYTICS',
  CHANGE_LOGS: 'PLUS_-_CHANGE_LOGS',
  COMPLIANCE_SIGNOFFS: 'COMPLIANCE_-_SIGNOFFS',
  CONSISTENCY_SCORES: 'PLUS_-_CONSITENCY_SCORES',
  FLSA: 'COMPLIANCE_-_FLSA',
  GRAMMARLY: 'PLUS_-_GRAMMARLY',
  INTEGRATIONS: 'ADDON_-_MERGE_INTEGRATIONS',
  JOB_HEALTH: 'PLUS_-_JOB_HEALTH',
  MULTIPLE_TEMPLATES: 'COMPLIANCE_-_MULTIPLE_TEMPLATES',
  ORG_CHART: 'PLUS_-_ORG_CHART',
  PROGRESS_TRACKING: 'PLUS_-_PROGRESS_TRACKING',
  REVIEW_CYCLE: 'PLUS_-_REVIEW_CYCLES',
  TRACK_CHANGES: 'PLUS_-_TRACK_CHANGES',
  UNLIMITED_LOGS: 'COMPLIANCE_-_UNLIMITED_LOGS',
  ONLINE_INTERVIEWING: 'ONLINE_INTERVIEWING',
  TELEPHONE_INTERVIEWING: 'TELEPHONE_INTERVIEWING',
  OPENINGS: 'INTERVIEW_OPENINGS'
};

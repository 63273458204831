import CRUDService from '@/services/hrsg/CRUDService';
import { computed } from 'vue';
import store from '@/store';

class TagsService extends CRUDService {
  constructor () {
    super('content.tags');
  }

  async index (search = false, relationships = [], all = false) {
    const response = await this.request({
      endpoint: `${this.endpoint}.index`,
      queryParams: {
        ro_p: all ? -1 : 20,
        ro_r: [...relationships],
        ro_f: search ? JSON.stringify([{ f: ['texts.name'], c: 'LIKE', v: [search] }]) : []
      }
    });

    return this.getArrayResponse(response);
  }

  async update (id, data) {
    const response = await super.update(id, data);
    return this.getArrayResponse(response)[id];
  }

  async tagItem (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.tag-item`,
      data
    });

    return response?.payload?.data;
  }

  async create (data) {
    const response = await super.create(data);

    return response?.payload?.data;
  }

  async untagItem (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.untag-item`,
      pathParams: this.getPathvars({ id })
    });

    return response?.payload?.data;
  }

  getOptions () {
    return computed(() => {
      return store.getters['options/getOptions']('Tag');
    });
  }
}

export default new TagsService();

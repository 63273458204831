<template>
  <VTask
    :task="task"
    v-bind="$attrs"
  />
</template>

<script>
// Components
import VTask from '@/components/templates/tasks/VTask';

export default {
  components: {
    VTask
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

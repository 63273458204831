<template>
  <VNotification
    :item="notification"
    name="content-jobs-id-edit"
    @toggle-read="$emit('toggle-read',notification)"
    @close="$emit('close')"
  >
    <template #header>
      <span>
        {{ $t(`notification_${notification.type}`,{ name: actionTakenBy }) }}
      </span>
    </template>

    <template #type>
      {{ $t('notification_job_collaboration', { job: notification.data?.jobName }) }}
    </template>

    <template #content>
      <div class="inline">
        <span v-if="notification?.data?.reviewSummary">
          <VIcon
            v-if="notification?.data?.reviewSummary === ALL_OPTIONS.GOOD_TO_GO.value"
            icon="done"
            class="inline !text-green-1 !font-semibold mr-1 align-middle"
            size="text-[18px]"
          />
          <VStatus
            v-else
            status="Reviewed"
            class="!mr-2 inline-block"
          />

          <span
            class="text-sm text-grey-1"
            v-html="notification?.data?.reviewSummary"
          />
          <span class="px-1">-</span>
        </span>

        <span
          v-if="TYPES_FEEDBACK.includes(notification?.type)"
          class="text-sm text-grey-1"
        >
          <span v-html="notification?.data?.feedback" />

          <span v-html="notification?.data?.comment" />
        </span>

        <div
          v-if="TYPES_COMMENTS.includes(notification?.type)"
          class="!text-grey-1 !text-sm"
        >
          <span v-html="displayedComments(notification?.data?.comment)" />
        </div>
      </div>
    </template>
  </VNotification>
</template>

<script>
import { computed } from 'vue';
import ALL_OPTIONS from '@/constants/options';
// Components
import VIcon from '@/components/VIcon';
import VNotification from '@/components/templates/notifications/VNotification';
import VStatus from '@/components/VStatus.vue';

export default {
  components: {
    VStatus,
    VNotification,
    VIcon
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props) {
    // Constants
    const TYPES_FEEDBACK = ['JobFeedbackProvidedToOwner', 'JobFeedbackProvidedToContributorInThread'];
    const TYPES_COMMENTS = ['JobComment', 'JobCommentMention'];

    // Computed
    const userAvatar = computed(() => {
      const { notification: { type, data } } = props;

      if (TYPES_FEEDBACK.includes(type)) {
        return data.contributorAvatar ? { avatar: { view_path: data.contributorAvatar } } : { firstname: data.contributorName };
      }
      return data.commentByAvatar ? { avatar: { view_path: data.commentByAvatar } } : { firstname: data.commentByName };
    });

    const actionTakenBy = computed(() => {
      const { notification: { type, data } } = props;

      if (TYPES_FEEDBACK.includes(type)) {
        return data?.contributorName;
      }
      return data?.commentByName;
    });

    // Methods
    const displayedComments = (comment) => {
      if (!comment) return '';

      return comment.replace(/@#(\d+),([\w\s]+)#@/g, '<span class="text-blue-1 bg-light-blue-2 rounded-full px-0.75" data-user-id="$1">@$2</span>');
    };

    return {
      userAvatar,
      actionTakenBy,
      TYPES_FEEDBACK,
      TYPES_COMMENTS,
      displayedComments,
      ALL_OPTIONS
    };
  }
};
</script>

<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    :enabled="task.status === 'Open'"
    @click="onClickReview"
  >
    <template #default>
      {{ $t('scheduled_review') }}
    </template>

    <template #open>
      <VButton
        class="secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg round"
        :class="btnSize"
        @click="onClickReview"
      >
        {{ $t('review') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';
// Services
import JobService from '@/services/hrsg/content/jobs/JobService';

export default {
  components: {
    VTask,
    VButton
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // Misc
    const router = useRouter();

    // Methods
    const onClickReview = async () => {
      if (props.task?.status !== 'Open') return;
      if (!props.task?.taskable_id) return;

      const draftCopyID = await JobService.getDraftCopyID(props.task?.taskable_id);
      const name = draftCopyID ? 'content-jobs-id-edit' : 'content-jobs-id-description';
      const id = draftCopyID || props.task?.taskable_id;

      router.push({ name, params: { id } });
    };

    return {
      onClickReview
    };
  }
};
</script>

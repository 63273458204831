import { createStore } from 'vuex';

import api from './modules/api';
import filters from './modules/filters';
import user from './modules/user';
import client from './modules/client';
import toast from './modules/toast';
import options from './modules/options';
import tasks from './modules/tasks';
import pendingJobUpdates from './modules/pendingJobUpdates';
import contentFinder from './modules/contentFinder';
import job from './modules/job';
import breadcrumbs from './modules/breadcrumbs';

export default createStore({
  modules: {
    breadcrumbs,
    api,
    filters,
    user,
    client,
    toast,
    options,
    tasks,
    pendingJobUpdates,
    contentFinder,
    job
  }
});

export default function hasPermission (...libraries) {
  return ({ next, store, nextVue }) => {
    const isAuthorized = libraries.some(library => store.getters['client/hasLibraryContent'](library));

    if (!isAuthorized) {
      return nextVue({ name: 'error' });
    }

    return next();
  };
}

import CRUDService from '@/services/hrsg/CRUDService';

class CompetencyGroupService extends CRUDService {
  constructor () {
    super('content.competency-levels');
  }

  async reorder (data) {
    return await this.request({
      endpoint: `${this.endpoint}.reorder`,
      pathParams: this.getPathvars(),
      data
    });
  }
}

export default new CompetencyGroupService();

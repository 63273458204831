import PendingJobUpdateService from '@/services/hrsg/administration/PendingJobUpdateService';
import permissions from '@/constants/permissions';

const hasPermission = context => [
  permissions.MANAGE_LIBRARIES,
  permissions.MANAGE_JOBS,
  permissions.CONTENT_ADMINISTRATOR
].some(x => context.rootGetters['user/hasPermission'](x));

const state = () => {
  return {
    isActive: false,
    isShowing: false,
    wasShown: false,
    initiatedBy: null,
    data: []
  };
};

const getters = {
  data: state => state.data,
  initiatedBy: state => state?.initiatedBy,
  isActive: state => state.isActive,
  isShowing: state => state.isShowing
};

const actions = {
  async initiate (context, values) {
    context.dispatch('setIsActive', true);

    const response = await PendingJobUpdateService.initiate({
      updates_for: values?.updates_for.toString(),
      update_type: values?.update_type
    });

    context.dispatch('setInitiatedBy', response?.initiated_by);
  },
  async getData (context) {
    if (!hasPermission(context)) return;

    const response = await PendingJobUpdateService.index();
    context.commit('SET_DATA', response);

    if (response?.length > 0) {
      context.dispatch('setIsShowing', true);
    }
  },
  async getStatus (context) {
    if (!hasPermission(context)) return;

    const response = await PendingJobUpdateService.status();

    switch (response?.status) {
      case 'All updated':
        break;
      case 'Pending updates':
        context.dispatch('getData');
        break;
      case 'Updates in progress':
        context.dispatch('setIsActive', true);
        context.dispatch('setInitiatedBy', response?.initiated_by);
        break;
    }
  },
  setInitiatedBy (context, value) {
    context.commit('SET_INITIATED_BY', value);
  },
  setIsActive (context, value) {
    context.commit('SET_IS_ACTIVE', value);
  },
  setIsShowing (context, value) {
    const wasShown = localStorage.getItem('pending_job_updates_was_shown');
    if (wasShown || context.state.wasShown) return;

    context.commit('SET_IS_SHOWING', value);
    context.commit('SET_WAS_SHOWN', true);
  }
};

const mutations = {
  SET_DATA (state, value) {
    state.data = value;
  },
  SET_INITIATED_BY (state, value) {
    state.initiatedBy = value;
  },
  SET_IS_ACTIVE (state, value) {
    state.isActive = value;
  },
  SET_IS_SHOWING (state, value) {
    state.isShowing = value;
  },
  SET_WAS_SHOWN (state, value) {
    state.wasShown = value;
    localStorage.setItem('pending_job_updates_was_shown', true);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

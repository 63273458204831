<template>
  <TransitionRoot
    as="template"
    :show="show"
  >
    <Dialog
      as="div"
      class="relative z-40 text-md"
      @close="onClose"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-30 inset-0 overflow-hidden">
        <div
          class="flex justify-center items-start text-center h-full py-20"
          :class="dialogClass"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:max-w-lg sm:w-full flex flex-col max-h-full"
              :class="[
                overflow !== 'visible' ? 'overflow-hidden' : '',
                panelClass
              ]"
            >
              <div
                v-if="$slots.title"
                :class="titleClass"
                class="border-b border-b-light-gray-4 text-xl font-medium py-6 pl-8 pr-1 flex justify-between items-center flex-none"
              >
                <div class="w-11/12">
                  <slot name="title" />
                </div>

                <div
                  :class="hideIconContentClass"
                  class="flex items-center justify-center w-[75px]"
                >
                  <button
                    v-if="!hideClose"
                    class="top-[2px] outline-none right-5 top-6"
                  >
                    <XIcon
                      :class="hideIconClass"
                      class="w-5 h-5 cursor-pointer text-grey-2 hover:text-blue-2 flex"
                      @click="$emit('hide')"
                    />
                  </button>
                </div>
              </div>

              <div
                class="bg-white p-8 pb-10"
                :class="`overflow-${overflow} ${contentClass}`"
              >
                <slot name="content" />
              </div>

              <div
                v-if="$slots.actions"
                class="border-t border-t-light-gray-4 px-8 border-color-grey-9 py-5 sm:flex sm:justify-between items-center gap-2.5 flex-none"
              >
                <div class="flexwrap w-1/2">
                  <slot name="message" />
                </div>

                <div
                  class="w-1/2 flex justify-end items-center gap-2.5"
                  :class="actionsClass"
                >
                  <slot name="actions" />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    overflow: { // overflow-hidden, overflow-auto, overflow-visible
      type: String,
      default: 'auto',
      validator: value => {
        const validProps = [
          'hidden',
          'auto',
          'visible'
        ];
        return validProps.includes(value);
      }
    },
    dismissOnBackdropClick: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    panelClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    hideIconContentClass: {
      type: String,
      default: ''
    },
    hideIconClass: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    actionsClass: {
      type: String,
      default: ''
    },
    dialogClass: {
      type: String,
      default: ''
    }
  },
  emits: ['hide'],
  setup (props, context) {
    const onClose = () => {
      if (props.dismissOnBackdropClick) {
        context.emit('hide');
      }
    };

    return {
      onClose
    };
  }
};
</script>

<style>
.border-color-grey-9 {
  border-color: #E7EBED;
}
.grey-4 {
  color: #999999;
}
.border-t-light-gray-4 {
  border-top-color: #E7EBED;
}
.border-b-light-gray-4 {
  border-bottom-color: #E7EBED;
}
</style>

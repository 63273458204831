<template>
  <div>
    <VToast
      v-for="(toast, index) in toasts"
      :id="toast.id"
      :key="toast.id"
      :index="index"
      :status="toast.status"
      :message="toast.text"
      @close="onCloseHideToast(toast.id)"
      @mouseover="onMouseover(toast.id)"
      @mouseout="onMouseout(toast.id)"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import store from '@/store';
// Components
import VToast from '@/components/VToast';

export default {
  components: {
    VToast
  },
  setup () {
    // Computed
    const toasts = computed(() => store.getters['toast/toasts']);

    // Methods
    const onCloseHideToast = id => {
      store.dispatch('toast/hide', id);
    };

    const onMouseover = id => {
      store.dispatch('toast/mouseover', id);
    };

    const onMouseout = id => {
      store.dispatch('toast/mouseout', id);
    };

    return {
      toasts,
      onCloseHideToast,
      onMouseover,
      onMouseout
    };
  }
};
</script>

<template>
  <div
    class="btn-group relative inline-flex rounded"
    :class="'gap-'+gap"
  >
    <button
      type="button"
      class="btn-group-item btn-group-item-1 relative inline-flex items-center rounded-l font-medium"
      :class="buttonClass"
      @click="$emit('click')"
    >
      {{ label }}
    </button>

    <Menu
      ref="root"
      as="div"
      class="-ml-px relative block"
    >
      <MenuButton
        class="btn-group-item btn-group-item-2 relative inline-flex items-center rounded-r font-medium"
        :class="arrowButtonClass"
      >
        <span class="sr-only">Open options</span>

        <ChevronDownIcon
          class="chev-icon chev-med"
          aria-hidden="true"
        />
      </MenuButton>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          ref="menu"
          class="origin-top-right z-20 mt-2 -mr-1 w-max rounded-md shadow-floating bg-white focus:outline-none"
          :class="fixed ? 'fixed' : 'absolute right-0'"
          :style="fixed ? position : ''"
        >
          <div class="py-1">
            <MenuItem
              v-for="option in options"
              :key="option.name"
              v-slot="{ active }"
            >
              <a
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-grey-1', 'cursor-pointer block px-4 py-2 text-sm']"
                @click="onClick(option)"
              >
                {{ option.text }}
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { ref, computed } from 'vue';
import { helpers } from '@/utils/helpers';

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: [Number, String],
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    arrowButtonClass: {
      type: [Array, Object, String],
      default: ''
    },
    gap: {
      type: String,
      default: '0'
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'click:option'],
  setup (props, context) {
    // Ref
    const root = ref(null);
    const menu = ref(null);
    // Computed
    const position = computed(() => props.fixed ? helpers.calcPosition(root.value?.$el?.getBoundingClientRect(), menu.value?.$el?.getBoundingClientRect(), 'ssw', 8, 4, props.fixed) : {});

    // Methods
    const onClick = option => {
      context.emit('click:option', option);

      if (!option?.click) {
        return;
      }

      option.click(option);
    };

    return {
      root,
      menu,
      position,
      onClick
    };
  }
};
</script>

import { ref } from 'vue';

export class ModalControl {
  // Data
  isModalOpen = ref(false);

  // Methods
  setIsModalOpen = (value) => {
    this.isModalOpen.value = value;
  };

  hideModal = () => {
    this.setIsModalOpen(false);
  };

  showModal = () => {
    this.setIsModalOpen(true);
  };
}

/**
 * @returns { ModalControl }
 */
export default function useModal () {
  /** @type { ModalControl } */
  const instance = new ModalControl();
  return instance;
}

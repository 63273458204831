import AuthenticationService from '@/services/hrsg/platform/AuthenticationService';

export default async function auth ({ next, store, nextVue }) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const password = params.get('password');
  const authenticationCode = params.get('authentication_code');
  const oldPassword = params.get('old_password');
  const from = params.get('from');

  const isLoggedIn = store.getters['user/isLoggedIn'];
  const loggedEmail = store.getters['user/user']?.email;
  const pathname = from ?? window.location.pathname;
  const path = ['/login', '/create-password', '/reset-password'].includes(pathname) ? undefined : pathname;

  if (email && oldPassword) {
    if (isLoggedIn) {
      if (loggedEmail === email) {
        return next();
      }

      await AuthenticationService.logout();
    }

    return nextVue({
      name: 'create-password',
      query: {
        email,
        old_password: oldPassword,
        from: path
      }
    });
  }

  if (email && password && authenticationCode && !isLoggedIn) {
    return nextVue({
      name: 'login',
      query: {
        email,
        password,
        authentication_code: authenticationCode,
        from: path
      }
    });
  }

  if (!isLoggedIn) {
    return nextVue({ name: 'login', query: { from: path } });
  }

  return next();
}

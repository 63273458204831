export default function hasPermission (...permissions) {
  return ({ next, store, nextVue }) => {
    const isAuthorized = permissions.some(permission => store.getters['user/hasPermission'](permission));

    if (!isAuthorized) {
      return nextVue({ name: 'error' });
    }

    return next();
  };
}

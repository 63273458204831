<template>
  <VNotification
    :item="notification"
    @toggle-read="$emit('toggle-read',notification)"
    @close="$emit('close')"
  >
    <template #header>
      {{ notification.data?.contributorName }} {{ $t('notification_replied_to_your_feedback') }}
    </template>
    <template #type>
      {{ $t('notification_job_collaboration', { job: notification.data?.jobName }) }}
    </template>
    <template #content>
      <div
        class="text-xs"
        v-html="notification?.data?.feedback"
      />
    </template>
  </VNotification>
</template>

<script>
// Components
import VNotification from '@/components/templates/notifications/VNotification';
export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close']
};
</script>

export default [
  'COMPETENCIES',
  'RESPONSIBILITIES',
  'SKILLS',
  'CERTIFICATIONS',
  'EDUCATIONS',
  'EXPERIENCES',
  'LANGUAGES',
  'WORKING_CONDITIONS',
  'ADDITIONAL_INFO',
  'INTERVIEW_QUESTIONS',
  'JOBS',
  'LEARNING_RESOURCES'
].reduce((acc, cv) => ({ ...acc, [cv]: cv }), {});

import { computed } from 'vue';
import store from '@/store';
import * as yup from 'yup';
import i18n from '@/i18n';

export default function useTexts () {
  const t = i18n.global.t;

  // Computed
  const userLanguage = computed(() => store.getters['user/language']);
  const defaultLanguage = computed(() => store.getters['client/default_language']);
  const language = computed(() => userLanguage.value || defaultLanguage.value);
  const availableLanguages = computed(() => store.getters['client/available_languages'] ?? []);

  // Methods
  /**
   * @param {Array<Array>} pairs - array of key/value pairs
   * @param {String} language - language to assign the key/value pairs to
   * @returns
   */
  const buildTexts = (pairs = [], language) => {
    return availableLanguages.value.map(x => ({
      language: x,
      ...pairs.reduce((acc, cv) => ({
        ...acc,
        [cv[0]]: (x === language || userLanguage.value) ? cv[1] : ''
      }), {})
    }));
  };

  /**
   * Returns the keys that match the multilingual fields
   * @param {Object} obj
   * @param {Array<String>} fields - the multilingual fields
   * @returns {Array<String>} the keys of the multilingual fields
   */
  const getTextKeys = (obj = {}, fields = []) => Object.keys(obj).filter(key => fields.includes(key));

  /**
   * Returns the text by its field and language.
   * @param {Array<Object>} texts
   * @param {String} field - the field in which you want to get the text.
   * @param {String} lang - the language in which you want to get the text. Defaults with user language.
   * @param {Boolean} useFallbackLanguages - flag to get other text's language if original does not exist. Defaults to true.
   * @returns {String} text - the text filtered by the field and language. Fallbacks to ''
   */
  const getText = (texts = [], field = '', lang = '', useFallbackLanguages = true) => {
    const language = lang || userLanguage.value;
    const fallback = '';

    if (texts.length === 0 || !field) {
      return fallback;
    }

    let text = texts.find(text => text.language === language);

    if (!useFallbackLanguages) {
      return (field ? text?.[field] : text) ?? fallback;
    }

    if (!text) {
      text = texts.find(text => text.language === defaultLanguage.value);
    }

    if (!text) {
      text = texts[0];
    }

    return (field ? text?.[field] : text) ?? fallback;
  };

  /**
   * Returns a validation for the multilingual fields
   * @param {Object} schema - validation schema without the multilingual validation (Vee-validate + yup)
   * @param {Array<String>} fields - the multilingual fields
   * @returns {Object} the validation schema for the multilingual fields
   */
  const getTextsValidation = (schema, fields) => {
    const textKeys = getTextKeys(schema, fields);
    const texts = availableLanguages.value.reduce((acc, lang) => {
      const text = textKeys.reduce((acc, key) => {
        const existing = schema[key].notRequired();
        return {
          ...acc,
          [key]: yup.string().trim().concat(existing),
          language: yup.string().required().test('has-lang', '', v => availableLanguages.value.includes(v))
        };
      }, {});

      return [...acc, yup.object(text)];
    }, []);

    if (texts.length === 0) {
      return {};
    }

    return {
      texts: yup
        .array(...texts)
        .test('has-language-value', '', value => {
          return fields.every(field => {
            const exclusiveTests = schema?.[field]?.exclusiveTests ?? {};
            const isRequired = 'required' in exclusiveTests;
            const hasValue = value?.some(text => !!text[field]) ?? false;
            return !isRequired || (isRequired && hasValue);
          });
        })
        .test('has-details-for-name', t('translated_name_required'), value => {
          return value?.every(x => !x.details || (x.details && x.name));
        })
    };
  };

  /**
   * Returns an object that doesn't have the multilingual fields
   * @param {Object} obj
   * @param {Array<String>} fields - the multilingual fields
   * @returns {Object} object without the multilingual fields
   */
  const getNonTextFields = (obj, fields) => {
    return Object.keys(obj)
      .filter(key => !fields.includes(key))
      .reduce((acc, key) => ({
        ...acc,
        [key]: obj[key]
      }), {});
  };

  /**
   * Returns initial values for the multilingual fields
   * @param {Object} initialValues - the initial values
   * @param {Array<String>} fields - the multilingual fields
   * @returns {Object} - the multilingual intial values
   */
  const getTextsInitialValues = (initialValues, fields) => {
    const textKeys = getTextKeys(initialValues, fields);

    const texts = availableLanguages.value.reduce((acc, lang) => {
      const text = textKeys.reduce((acc1, key) => {
        let value = initialValues[key];

        if (Array.isArray(value)) {
          value = getText(value, key, lang, false);
        }

        return {
          ...acc1,
          [key]: value,
          language: lang
        };
      }, {});

      return [...acc, text];
    }, []);

    if (Object.keys(texts).length === 0) {
      return {};
    }

    return { texts };
  };

  /**
   * Returns a multilingual validation schema
   * @param {Object} schema - the schema without the multilingual validation
   * @param {Array<String>} fields - the fields within the schema that needs to be multilingual
   * @returns {Object} the multilingual validation schema
   */
  const getValidationSchema = (schema, fields = []) => {
    if (fields.length === 0) {
      return schema;
    }

    return {
      ...getTextsValidation(schema, fields),
      ...getNonTextFields(schema, fields)
    };
  };

  const hasField = (texts = [], field) => texts.some(text => text[field]);

  /**
   * Check if texts has all the languages
   * @param {Array} texts - the texts checked against.
   * @param {String} field - The field to test
   * @param {Array} languages - the languages checked against. Default to the app's available languages.
   * @returns {Boolean} true if has all the languages, false otherwise.
   */
  const hasAllLanguages = (texts = [], field, languages) => {
    const langs = languages ?? availableLanguages.value;

    if (langs.length === 1) return true;

    return langs.every(language => {
      const hasLanguage = texts.some(text => text.language === language);
      const hasField = texts.every(text => text[field]);

      return hasLanguage && hasField;
    });
  };

  /**
   * Returns multilingual initial values
   * @param {Object} initialValues - the initial values without the multilingual values
   * @param {Array<String>} fields - the fields that need to be considered as multilingual at initial value
   * @returns {Object} the multilingual initial values
   */
  const getInitialValues = (initialValues, fields = []) => {
    if (fields.length === 0) {
      return initialValues;
    }

    return {
      ...getTextsInitialValues(initialValues, fields),
      ...getNonTextFields(initialValues, fields)
    };
  };

  /**
   * Returns multilingual initial values
   * @param {Object} texts
   * @param {String} field - the field in which you want to get the text.
   * @param {String} lang - the language in which you want to get the text. Defaults with user language.
   * @param {Boolean} useFallbackLanguages - flag to get other text's language if original does not exist. Defaults to true.
   * @returns {String} text - the text filtered by the field and language. Fallbacks to ''
   */
  const getObjText = (texts = {}, field = '', lang = '', useFallbackLanguages = false) => {
    const language = lang || userLanguage.value;
    const fallback = '';

    if (!texts || !field) {
      return fallback;
    }

    const key = Object.keys(texts).filter(key => key === field);
    if (key.length > 0) {
      if (texts[key]?.[language]) {
        return texts[key]?.[language];
      }

      if (useFallbackLanguages && texts[key]?.[defaultLanguage.value]) {
        return texts[key]?.[defaultLanguage.value];
      }
    }
    return fallback;
  };

  return {
    language,
    userLanguage,
    availableLanguages,
    buildTexts,
    hasField,
    hasAllLanguages,
    getText,
    getValidationSchema,
    getInitialValues,
    getObjText
  };
}

<template>
  <div
    v-if="hasSiteUpdates"
    class="flex gap-1 p-4 h-14 bg-light-orange-1 border-b border-orange-3b flex justify-center items-center gap-1"
  >
    <div class="text-orange-0 font-medium text-md">
      {{ $t('site_update_message') }}
    </div>

    <VButton
      class="text !bg-transparent"
      @click="onClickRefreshPage"
    >
      <div class="font-medium text-md">
        {{ $t('page_refresh') }}
      </div>
    </VButton>
  </div>
</template>

<script>
import { computed } from 'vue';
import store from '@/store';
// Components
import VButton from '@/components/VButton';

export default {
  components: {
    VButton
  },
  setup () {
    // Computed
    const hasSiteUpdates = computed(() => store.getters['client/hasSiteUpdates']);

    // Methods
    const clearLocalStorage = () => {
      const clearKeys = ['filters'];

      Object.keys(localStorage).forEach(key => {
        if (clearKeys.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    };

    const onClickRefreshPage = async () => {
      store.dispatch('client/setSiteUpdates', false);
      window.location.reload();
      clearLocalStorage();
    };

    return {
      hasSiteUpdates,
      onClickRefreshPage
    };
  }
};
</script>

import useRequest from '@/composables/useRequest';

const {
  request,
  getFirstElementResponse,
  getArrayResponse
} = useRequest();

export default class {
  request (...params) {
    return request(...params);
  }

  getFirstItem (...params) {
    return getFirstElementResponse(...params);
  }

  getArrayResponse (...params) {
    return getArrayResponse(...params);
  }
}

<template>
  <span
    ref="root"
    class="relative"
  >
    <span
      v-if="displayedTooltip || $slots.content"
      ref="tip"
      class="tooltip text-left font-normal text-xs max-w-[21rem] whitespace-normal min-w-min w-max break-words absolute px-2.5 py-1.5 rounded"
      :class="[{ 'hidden': !isHovered || forceClosed }, type, tipClass, fixed ? '!fixed' : '']"
      :style="position"
    >
      <slot name="content">
        {{ displayedTooltip }}
      </slot>
    </span>

    <span
      :class="contentClass"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
      @click="onClickTooltip"
    >
      <slot />
    </span>
  </span>
</template>

<script>
import { ref, onMounted, nextTick, watch } from 'vue';
import { helpers } from '@/utils/helpers';

export default {
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    clickTooltip: {
      type: String,
      default: ''
    },
    onClickClose: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'tooltip-light',
      validator: value => {
        return ['tooltip-light', 'tooltip-dark'].includes(value);
      }
    },
    tipClass: {
      type: [String, Array],
      default: ''
    },
    contentClass: {
      type: [String, Array],
      default: ''
    },
    fixed: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: false
    },
    forceClosed: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'sse',
      validator: value => {
        return [
          'wsw',
          'w',
          'wnw',
          'nw',
          'nnw',
          'n',
          'nne',
          'ne',
          'ene',
          'e',
          'ese',
          'se',
          'sse',
          's',
          'ssw',
          'sw'
        ].includes(value);
      }
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    // Data
    const isHovered = ref(false);
    const displayedTooltip = ref(context.slots.content ?? props.tooltip);
    const root = ref(null);
    const tip = ref(null);
    const position = ref(null);
    let delayTimeout = null;

    const getPosition = () => {
      if (tip.value) {
        tip.value.style.display = 'block';
        const position = helpers.calcPosition(root.value.getBoundingClientRect(), tip.value.getBoundingClientRect(), props.direction, 8, 4, props.fixed, props.responsive);

        tip.value.style.display = '';
        return position;
      }
      return {};
    };

    const onClickTooltip = () => {
      if (props.onClickClose) {
        isHovered.value = false;
        return;
      }
      if (props.clickTooltip.length) {
        displayedTooltip.value = props.clickTooltip;
      }
    };

    const onMouseOver = () => {
      delayTimeout = setTimeout(() => {
        isHovered.value = !!(context.slots.content ?? displayedTooltip);
        setPosition();
      }, props.delay);
    };

    const onMouseOut = () => {
      isHovered.value = false;
      displayedTooltip.value = props.tooltip;
      clearTimeout(delayTimeout);
    };

    const setPosition = async () => {
      await nextTick();
      position.value = getPosition();
    };

    // Watchers
    watch(() => (props.tooltip), () => {
      displayedTooltip.value = props.tooltip;
    });

    watch(displayedTooltip, setPosition);

    // Lifecycle Hooks
    onMounted(setPosition);

    return {
      position,
      root,
      tip,
      isHovered,
      displayedTooltip,
      getPosition,
      onClickTooltip,
      onMouseOut,
      onMouseOver
    };
  }
};
</script>

<style lang="scss">
.tooltip {
  box-shadow: 0 1px 13px 0px rgba(0, 0, 0, 0.14);
  z-index: 100 !important;

  &.tooltip-light {
    @apply bg-grey-1 text-white border-gray-200;
  }
  &.tooltip-dark {
    @apply bg-blue-1 text-white border-blue-1;
  }
}
</style>

<template>
  <div>
    <VTooltip
      v-if="alwaysShowTooltip || (text && text.length > length)"
      :tooltip="text"
      :delay="delay"
    >
      {{ truncate(text, { length }) }}
    </VTooltip>

    <span v-else>
      {{ text }}
    </span>
  </div>
</template>

<script>
import { inject } from 'vue';
// Components
import VTooltip from '@/components/VTooltip';

export default {
  components: {
    VTooltip
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    length: {
      type: Number,
      default: 36
    },
    alwaysShowTooltip: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  setup () {
    // Inject
    const truncate = inject('lodash').truncate;

    return {
      truncate
    };
  }

};
</script>

const getIndex = (state, id) => state.toasts.findIndex(toast => toast.id === id);

const startTimer = (state, id) => {
  const index = getIndex(state, id);
  const { delay, createTimer } = state.toasts[index];
  createTimer(delay);
};

const stopTimer = (state, id) => {
  const index = getIndex(state, id);

  if (state.toasts[index]?.closing) {
    state.toasts[index].closing = true;
  }

  setTimeout(() => {
    state.toasts.splice(index, 1);
  }, 500);
};

const state = () => {
  return {
    toasts: []
  };
};

const getters = {
  toasts: state => state.toasts,
  getClosing: state => id => {
    const index = getIndex(state, id);

    return index !== -1 ? state.toasts[index].closing : false;
  }
};

const actions = {
  show ({ commit }, value) {
    commit('SHOW', value);
  },
  mouseover ({ commit }, index) {
    commit('MOUSEOVER', index);
  },
  mouseout ({ commit }, index) {
    commit('MOUSEOUT', index);
  },
  hide ({ commit }, index) {
    commit('HIDE', index);
  },
  closeAllManuallyDismissibleToasts ({ commit }) {
    commit('CLOSE_ALL_MANUALLY_DISMISSIBLE_TOASTS');
  }
};

const mutations = {
  SHOW (state, value) {
    const id = state.toasts.length;

    state.toasts.push({
      status: '',
      text: '',
      delay: 3000,
      closing: false,
      ...value,
      id,
      createTimer: delay => {
        if (delay === -1) {
          return;
        }

        this.timer = setTimeout(() => {
          stopTimer(state, id, true);
        }, delay);
      },
      deleteTimer: () => {
        clearTimeout(this.timer);
      }
    });

    startTimer(state, id);
  },
  MOUSEOVER (state, id) {
    const index = getIndex(state, id);
    state.toasts[index].deleteTimer();
  },
  MOUSEOUT (state, id) {
    startTimer(state, id);
  },
  HIDE (state, id) {
    stopTimer(state, id);
  },
  CLOSE_ALL_MANUALLY_DISMISSIBLE_TOASTS (state) {
    state.toasts = state.toasts.filter(toast => toast.delay !== -1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    :enabled="task.status === 'Open'"
    @click="onClickSignoff"
  >
    <template #open>
      <VButton
        class="secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg"
        :class="btnSize"
        @click="onClickSignoff"
      >
        {{ $t('signoff') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';

export default {
  components: {
    VTask,
    VButton
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  emits: ['update'],
  setup (props) {
    // Misc
    const router = useRouter();

    // Methods
    const onClickSignoff = () => {
      if (props.task?.status !== 'Open') {
        return;
      }

      router.push({
        name: 'tasks-signoffs-id',
        params: {
          id: props.task?.taskable_id
        }
      });
    };

    return {
      onClickSignoff
    };
  }
};
</script>

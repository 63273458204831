import CRUDService from '@/services/hrsg/CRUDService';

export default class extends CRUDService {
  endpoint = null;

  /**
   * @param { String } endpoint
   */
  constructor (endpoint) {
    super(endpoint);
    this.endpoint = endpoint;

    this.verifyEndPointField();
  }

  /**
   * Creates multiple items
   * @param {Object} data
   */
  async addMultiple (data) {
    return await this.request({
      endpoint: `${this.endpoint}.store-multiple`,
      data
    });
  }

  /**
  * Publishes an item
  * @param {String|Number} id
  */
  async publish (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.publish`,
      pathParams: { id }
    });
  }

  /**
  * Check if an archived item has duplicates
  * @param {String|Number} data
  */
  async checkDuplicates (data) {
    if (!data) {
      return;
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.check-duplicates`,
      data
    });

    return response?.payload?.data?.has_duplicates;
  }

  /**
  * Restores an item
  * @param {String|Number} id
  */
  async restore (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.restore`,
      pathParams: { id }
    });
  }

  /**
  * Archives an item
  * @param {String|Number} id
  */
  async archive (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.archive`,
      pathParams: { id }
    });
  }

  async getJobIds (id, data) {
    if (!id || !data) {
      return;
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.job-ids`,
      pathParams: { id },
      data
    });

    return this.getArrayResponse(response);
  }
}

const state = () => {
  return {
    options: {}
  };
};

const getters = {
  getOptions: state => resourceType => state.options?.[resourceType]?.options
};

const actions = {
  async getOptions (context, data) {
    const stateOptions = context.state.options?.[data?.resource]?.options;

    if (!stateOptions) {
      const options = await data.getData();

      context.commit('SET_OPTIONS', {
        resource: data.resource,
        getData: data.getData,
        options
      });

      return options;
    }

    return stateOptions;
  },
  async resetOptions (context, input) {
    let resource;
    let realtime;
    if (input instanceof Object) {
      ({ resource, realtime } = input);
    } else {
      resource = input;
      realtime = false;
    }

    if (!resource || !context.state.options[resource]) {
      return;
    }

    if (!realtime) {
      context.state.options[resource].options = undefined;
    }
    const options = await context.state.options[resource].getData();
    context.state.options[resource].options = options;
  }
};

const mutations = {
  SET_OPTIONS (state, { resource, getData, options }) {
    if (!state.options[resource]) {
      state.options = {
        ...state.options,
        [resource]: {
          getData,
          options: []
        }
      };
    }

    state.options[resource].options = options;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <img
    v-if="isCustom"
    :class="['inline', color, weight, size]"
    :src="require(`@/assets/icons/${icon}.svg`)"
  >
  <span
    v-else
    class="material-symbols-rounded select-none"
    :class="[color, weight, size]"
  >
    {{ icon }}
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    isCustom: {
      type: Boolean,
      default: false
    },
    color: {
      type: [String, Object],
      default: 'text-grey-5'
    },
    weight: {
      type: String,
      default: 'font-normal'
    },
    size: {
      type: String,
      default: 'text-[20px]'
    }
  }
};
</script>

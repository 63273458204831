<template>
  <div class="pt-1">
    <div class="sm:hidden">
      <label
        for="tabs"
        class="sr-only"
      >
        Select a tab
      </label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option
          v-for="tab in tabs"
          :key="tab.value"
          :selected="tab.active"
        >
          {{ tab.text }}
        </option>
      </select>
    </div>

    <div class="hidden sm:block">
      <nav
        class="-mb-px inline-flex space-x-8"
        aria-label="Tabs"
      >
        <a
          v-for="tab in tabs"
          :key="tab.value"
          class="hover:text-grey-1 cursor-pointer whitespace-nowrap pt-5 pb-3 mx-1 first:ml-0 last:mr-0 border-b-[3px] font-semibold text-grey-1 text-sm"
          :class="[
            tab.active ? `border-grey-3 ${activeTabClass}` : `border-transparent ${inactiveTabClass}`,
            tabClass
          ]"
          :aria-active="tab.active ? 'page' : undefined"
          @click="$emit('click', tab)"
        >
          {{ tab.text }}
          <slot :option="tab" />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => [],
      validator: value => value.every(x => {
        const hasText = 'text' in x;
        const hasValue = 'value' in x;

        return hasText && hasValue;
      })
    },
    tabClass: {
      type: String,
      default: ''
    },
    hoverEffect: {
      type: Boolean,
      default: true
    },
    activeTabClass: {
      type: String,
      default: ''
    },
    inactiveTabClass: {
      type: String,
      default: 'text-grey-3'
    }
  },
  emits: ['click']
};
</script>

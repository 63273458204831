import VNavigate from '@/directives/vnavigate.js';
import VOnclickaway from '@/directives/vonclickaway.js';

const directives =
{
  navigate: VNavigate,
  onclickaway: VOnclickaway
};

/** @param { import("vue").App<Element> } app */
function registerDirectives (app) {
  const keys = Object.keys(directives);

  keys.forEach(directiveName => {
    app.directive(directiveName, directives[directiveName]);
  });
}

export default registerDirectives;

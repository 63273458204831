import CRUDService from '@/services/hrsg/CRUDService';
import useDownload from '@/composables/useDownload';
const { downloadFile } = useDownload();

class JobService extends CRUDService {
  constructor () {
    super('jobs');
  }

  async updateReviewDate (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.update-review-date`,
      pathParams: { id },
      data
    });
  }

  async versions (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.versions`,
      pathParams: { id }
    });

    return response?.payload?.data ?? [];
  }

  async getDraftCopyID (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.draft-copy-id`,
      pathParams: { id }
    });

    return response?.payload?.data ?? null;
  }

  async toggleFlagged (id, queryParams) {
    return await this.request({
      endpoint: `${this.endpoint}.toggle-flagged`,
      pathParams: { id },
      queryParams
    });
  }

  async toggleStarred (id, queryParams) {
    return await this.request({
      endpoint: `${this.endpoint}.toggle-starred`,
      pathParams: { id },
      queryParams
    });
  }

  async markReviewed (id) {
    return await this.request({
      endpoint: `${this.endpoint}.mark-reviewed`,
      pathParams: { id }
    });
  }

  async jobWarehouseImport (data) {
    if (!data) {
      throw new Error('Data parameter is required');
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.warehouse-import`,
      data
    });
    return response;
  }

  async edit (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.edit`,
      pathParams: { id }
    });
    return this.getArrayResponse(response);
  }

  async toggleTrack (id, isContinue = false) {
    const queryParams = {};
    if (isContinue) {
      queryParams.continue = true;
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.toggle-track`,
      pathParams: { id },
      queryParams
    });
    return this.getArrayResponse(response);
  }

  async getChanges (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.get-changes`,
      pathParams: { id }
    });
    return this.getArrayResponse(response);
  }

  async dueDate (id, date) {
    return await this.request({
      endpoint: `${this.endpoint}.due-date`,
      pathParams: { id },
      data: {
        due_date: date
      }
    });
  }

  async toggleFeedback (id) {
    return await this.request({
      endpoint: `${this.endpoint}.toggle-input`,
      pathParams: { id }
    });
  }

  async downloadFeedback (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.download-feedback`,
      pathParams: { id },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async relatedData (id, endpoint) {
    const response = await this.request({
      endpoint: `${this.endpoint}.${endpoint}`,
      pathParams: { id }
    });
    return this.getArrayResponse(response);
  }

  async provideFeedback (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.feedback`,
      pathParams: this.getPathvars({ id }),
      data
    });
    return response?.payload?.data;
  }

  async updateFeedback (jobId, feedbackId, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.update-feedback`,
      pathParams: { job_id: jobId, feedback_id: feedbackId },
      data
    });
    return response?.payload?.data;
  }

  async deleteFeedback (jobId, feedbackId) {
    const response = await this.request({
      endpoint: `${this.endpoint}.delete-feedback`,
      pathParams: { job_id: jobId, feedback_id: feedbackId }
    });
    return response?.payload?.data;
  }

  async updateSectionStatus (id, type, status) {
    const response = await this.request({
      endpoint: `${this.endpoint}.update-section-status`,
      pathParams: { id },
      data: {
        library_type: type,
        status
      }
    });

    return response?.payload?.data;
  }

  async checkDuplicates (data) {
    if (!data) {
      return;
    }

    const response = await this.request({
      endpoint: `${this.endpoint}.check-duplicates`,
      data
    });

    return response?.payload?.data?.has_duplicates;
  }

  async restore (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.restore`,
      pathParams: { id }
    });
  }

  async restoreArchived (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.restore-archived`,
      pathParams: { id }
    });
  }

  async archive (id, data) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.archive`,
      pathParams: { id },
      queryParams: data
    });
  }

  async duplicate (id) {
    if (!id) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.duplicate`,
      pathParams: { id }
    });
  }

  async move (id, data) {
    if (!id || !data) {
      return;
    }

    return await this.request({
      endpoint: `${this.endpoint}.move`,
      pathParams: { id },
      queryParams: data
    });
  }

  async assignTypes (data) {
    if (!data) return;

    return await this.request({
      endpoint: `${this.endpoint}.assign-types`,
      data
    });
  }

  async assignLevel (data) {
    if (!data) return;

    return await this.request({
      endpoint: `${this.endpoint}.assign-level`,
      data
    });
  }

  async publish (id, queryParams) {
    if (!id) return;

    return await this.request({
      endpoint: `${this.endpoint}.publish`,
      pathParams: { id },
      queryParams
    });
  }

  async getIncumbentsCount (data) {
    if (!data) return;

    const response = await this.request({
      endpoint: `${this.endpoint}.incumbents-count`,
      data
    });

    return response?.payload?.data?.incumbents_count ?? 0;
  }

  async getJobPostData (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.post-data`,
      pathParams: { id }
    });
    return this.getArrayResponse(response);
  }

  async getWarehouseMatches (data) {
    if (!data) return;
    const response = await this.request({
      endpoint: `${this.endpoint}.warehouse-matches`,
      data
    });
    return this.getArrayResponse(response);
  }

  async getWarehouseJob (id) {
    if (!id) return;
    const response = await this.request({
      endpoint: `${this.endpoint}.warehouse-job`,
      pathParams: { id }
    });
    return this.getArrayResponse(response);
  }

  async getSentenceItem (id) {
    if (!id) return;

    const response = await this.request({
      endpoint: `${this.endpoint}.heavy-libraries`,
      pathParams: { id }
    });

    return this.getArrayResponse(response) ?? {};
  }

  async atsPush (id) {
    if (!id) return;

    return await this.request({
      endpoint: `${this.endpoint}.ats-push`,
      pathParams: { id }
    });
  }

  async getSummaryAiSuggestion (ids) {
    const response = await this.request({
      endpoint: `${this.endpoint}.summary-ai-suggestion`,
      pathParams: { ids }
    });

    return response?.payload?.data ?? null;
  }

  async pdf (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.pdf`,
      pathParams: { id },
      data,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async docx (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.docx`,
      pathParams: { id },
      data,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async excel (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.xlsx`,
      pathParams: { id },
      data
    });
  }

  async multiplePDF (data) {
    return await this.request({
      endpoint: `${this.endpoint}.multiple-pdf`,
      data
    });
  }

  async multipleDocx (data) {
    return await this.request({
      endpoint: `${this.endpoint}.multiple-docx`,
      data
    });
  }

  async multipleExcel (data) {
    return await this.request({
      endpoint: `${this.endpoint}.multiple-xlsx`,
      data
    });
  }

  async download (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.download`,
      pathParams: { id }
    });

    if (response.message?.status === 'Failed') {
      return 404;
    }

    const downloadResponse = await this.request({
      endpoint: `${this.endpoint}.download`,
      pathParams: { id },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(downloadResponse);
  }

  async changeTemplate (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.change-template`,
      pathParams: { id },
      data
    });
  }

  async downloadSignoffPdf (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.download-signoff-pdf`,
      pathParams: { id },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }
}

export default new JobService();

import CRUDService from '@/services/hrsg/CRUDService';

export class PendingJobUpdateService extends CRUDService {
  constructor () {
    super('administration.pending-job-updates');
  }

  async status () {
    const response = await this.request({ endpoint: `${this.endpoint}.status` });

    return response?.payload?.data;
  }

  async initiate (data) {
    const endpoint = `${this.endpoint}.initiate`;

    if (!data) throw new Error(`Error on endpoint: ${endpoint}. Missing data.`);

    const response = await this.request({ endpoint, data });

    return response?.payload?.data;
  }
}

export default new PendingJobUpdateService();

<template>
  <div
    v-if="src"
    class="avatar inline-block overflow-hidden rounded-full"
    :class="`avatar-${size}`"
  >
    <img
      class="object-cover overflow-visible"
      :class="[imageClass, `avatar-${size}`]"
      :src="src"
      :alt="initials"
    >
  </div>

  <span
    v-else
    class="inline-flex items-center avatar justify-center rounded-full"
    :class="[`avatar-${size}`]"
  >
    <span
      class="leading-none select-none"
      :class="[imageClass]"
    >
      {{ initials }}
    </span>
  </span>
</template>

<script>
import { computed } from 'vue';
// Utils
import { helpers } from '@/utils/helpers';
// Store
import store from '@/store';

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    imageClass: {
      type: [String, Object],
      default: ''
    },
    size: {
      type: String,
      default: 'medium',
      validator: input => ['large', 'medium', 'small', 'x-small'].includes(input)
    }
  },
  setup (props) {
    // Computed
    const initials = computed(() => helpers.getInitials(props.user));
    const baseURL = computed(() => store.getters['client/api_url']);

    const src = computed(() => {
      if (props.user?.avatar?.view_path) {
        return `${baseURL.value}${props.user?.avatar?.view_path}`;
      }
      return '';
    });

    return {
      src,
      initials
    };
  }
};
</script>

<style scoped>
.avatar {
  @apply bg-white border border-grey-6 text-grey-2 font-medium;
}
.avatar-large {
  @apply w-10 h-10 text-[14px]
}
.avatar-medium {
  @apply w-8 h-8 text-xs
}
.avatar-small {
  @apply w-6 h-6 text-[10px]
}
.avatar-x-small {
  @apply w-5 h-5 text-[9px]
}
</style>

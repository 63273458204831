import CRUDService from '@/services/hrsg/CRUDService';

export class JobSalaryService extends CRUDService {
  constructor () {
    super('administration.job-salaries');
  }

  async reorder (data) {
    return await this.request({
      endpoint: `${this.endpoint}.reorder`,
      pathParams: this.getPathvars(),
      data
    });
  }
}

export default new JobSalaryService();

<template>
  <div class="relative text-grey-2">
    <div
      v-if="notifications?.length"
      class="flex flex-row-reverse mr-2.5 pb-4 pr-3 border-b"
    >
      <span
        class="cursor-pointer text-xs text-grey-1"
        :class="{ 'disabled-text !cursor-default' : !hasUnreadNotification }"
        @click="readAllNotification"
      >
        {{ $t('mark_all_as_read_all') }}
      </span>
    </div>

    <div
      ref="scrollElement"
      class="overflow-y-scroll max-h-[calc(100vh-225px)]"
    >
      <div v-if="notifications?.length">
        <div
          v-for="(item, index) in notifications"
          :key="index"
        >
          <component
            :is="getComponent(item)"
            :key="index"
            :class="{ 'border-b-0': index === notifications.length - 1 }"
            :notification="item"
            @toggle-read="toggleRead"
            @close="$emit('close')"
          />
        </div>
      </div>

      <div
        v-if="isLoading"
        class="flex items-center justify-center mt-3"
      >
        <VLoader
          class="h-5 w-5 text-blue-3 opacity-70"
          :stroke-width="2"
        />
      </div>

      <div
        v-if="!isLoading && notifications?.length === 0"
        class="text-blue-2 font-semibold pl-3"
      >
        {{ $t('you_are_all_upto_date') }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed, inject } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';
// Components
import VLoader from '@/components/VLoader';
import JobIncumbents from '@/components/templates/notifications/JobIncumbents';
import JobChangeRole from '@/components/templates/notifications/JobChangeRole';
import JobReplyOnFeedback from '@/components/templates/notifications/JobReplyOnFeedback';
import JobComment from '@/components/templates/notifications/JobComment';
import JobFeedbackOpenClose from '@/components/templates/notifications/JobFeedbackOpenClose';
import JobMultipleExport from '@/components/templates/notifications/JobMultipleExport';
import InterviewCandidacyComment from '@/components/templates/notifications/InterviewCandidacyComment';
import AllScorecardsSubmitted from '@/components/templates/notifications/AllScorecardsSubmitted';
// Composables
import useInfiniteScrolling from '@/composables/useInfiniteScrolling';
// Services
import NotificationService from '@/services/hrsg/platform/NotificationService';

export default {
  components: {
    VLoader,
    JobIncumbents,
    JobChangeRole,
    JobReplyOnFeedback,
    JobComment,
    JobFeedbackOpenClose,
    JobMultipleExport,
    InterviewCandidacyComment,
    AllScorecardsSubmitted
  },
  emits: ['close'],
  setup () {
    // Misc
    const { t } = useI18n();

    // Inject
    const dayjs = inject('dayjs');

    // Constants
    const ITEMS_PER_PAGE = 20;

    // Data
    const data = ref([]);
    const notificationJobStatus = ref({});
    const scrollElement = ref();

    // Computed
    const notifications = computed(() => {
      if (data.value?.notifications_list?.length === 0) return [];

      return data.value.notifications_list?.map(x => {
        const jobData = JSON.parse(x.data);
        const jobId = jobData?.jobId ?? null;
        const jobStatus = notificationJobStatus.value?.[jobId] ?? null;

        return {
          ...x,
          type: x.type.split('\\').pop(),
          data: jobData,
          date: formatDate(x.created_at),
          job_status: jobStatus
        };
      });
    });

    const hasUnreadNotification = computed(() => data.value?.unread_count !== 0);

    // Composables
    const { page, hasAllResults, isLoading, scroll } = useInfiniteScrolling();

    // Methods
    const jobIdsString = (data) => {
      return (data.notifications_list || [])
        .reduce((acc, x) => {
          const parsedData = JSON.parse(x.data);
          const jobId = parsedData?.jobId;
          if (jobId) acc.push(jobId);
          return acc;
        }, [])
        ?.join(',') || '';
    };

    const getData = async () => {
      const response = await NotificationService.index({
        page: page.value,
        ro_p: ITEMS_PER_PAGE
      });

      const ids = jobIdsString(response);
      await getJobStatuses(ids);

      page.value++;
      store.dispatch('user/setNotificationsCount', response?.unseen_count);
      return response;
    };

    const getJobStatuses = async (ids = '') => {
      const response = await NotificationService.getJobStatus(ids);

      if (response && typeof response === 'object') {
        Object.assign(notificationJobStatus.value, response);
      }
    };

    const getComponent = item => {
      const types = {
        JobContributorTypeChange: 'JobChangeRole',
        JobOpenForInputToggle: 'JobFeedbackOpenClose',
        JobPublished: 'JobIncumbents',
        JobPublishedToIncumbent: 'JobIncumbents',
        JobArchived: 'JobIncumbents',
        JobDeleted: 'JobIncumbents',
        JobComment: 'JobComment',
        JobFeedbackProvidedToOwner: 'JobComment',
        JobFeedbackProvidedToContributorInThread: 'JobComment',
        JobCommentMention: 'JobComment',
        JobMultipleExport: 'JobMultipleExport',
        InterviewCandidacyComment: 'InterviewCandidacyComment',
        AllScorecardsSubmitted: 'AllScorecardsSubmitted'
      };

      return types[item?.type] ?? '';
    };

    const readAllNotification = async () => {
      await NotificationService.readAll({ status: 'Completed' });
      page.value = 1;
      hasAllResults.value = false;
      data.value = await getData();
    };

    const toggleRead = async (event) => {
      await NotificationService.toggleRead(event?.id, { status: event?.read_at ? 'Open' : 'Completed' });
      page.value = 1;
      hasAllResults.value = false;
      data.value = await getData();
    };

    const formatDate = (someDate) => {
      const isToday = dayjs().isSame(someDate, 'day');
      const isYesterday = dayjs().subtract(1, 'day').isSame(someDate, 'day');

      if (isToday) {
        return t('today');
      }

      if (isYesterday) {
        return t('yesterday');
      }

      return dayjs(someDate).format('MMM D');
    };

    const updateNotificationCount = async () => {
      await NotificationService.seen();
    };

    // Watchers
    watch(scrollElement, newValue => {
      scroll(newValue, async () => {
        const response = await getData();

        if (response?.notifications_list?.length < ITEMS_PER_PAGE) {
          hasAllResults.value = true;
        }

        data.value.notifications_list.push(...response?.notifications_list ?? []);
      });
    });

    // Lifecycle Hooks
    onMounted(async () => {
      updateNotificationCount();
      data.value = await getData();
    });

    return {
      getData,
      scrollElement,
      data,
      isLoading,
      readAllNotification,
      toggleRead,
      getComponent,
      formatDate,
      notifications,
      hasUnreadNotification
    };
  }
};
</script>

<template>
  <div
    v-if="name"
    class="flex gap-1 p-4 h-14 bg-light-orange-1 border-b border-orange-3b flex justify-center items-center gap-1"
  >
    <div class="text-orange-0 font-medium text-md">
      {{ $t('you_are_logged_in_as', { name }) }}
    </div>

    <VButton
      class="text !bg-transparent"
      @click="onClickLogoutPersonify"
    >
      <div class="font-medium text-md">
        {{ $t('logout') }}
      </div>
    </VButton>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
// Components
import VButton from '@/components/VButton';
// Utils
import { helpers } from '@/utils/helpers';
// Services
import UserService from '@/services/hrsg/administration/UserService';

export default {
  components: {
    VButton
  },
  setup () {
    // Misc
    const router = useRouter();

    // Computed
    const personify = computed(() => store.getters['user/personify']);
    const user = computed(() => store.getters['user/current_user']);
    const name = computed(() => helpers.getFullName(personify.value));

    // Methods
    const onClickLogoutPersonify = async () => {
      await UserService.endPersonify(user.value?.id);
      store.dispatch('user/clearPersonifyUser');
      router.push({ name: 'home' });
    };

    return {
      name,
      onClickLogoutPersonify
    };
  }
};
</script>

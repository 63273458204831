import TABLE_FILTERS from '@/constants/tableFilters';
import _ from 'lodash';

const getTableFilters = (state, tableKey) => state.filters?.[tableKey] ?? {};
const getFilter = (state, tableKey, filterKey) => state.filters?.[tableKey]?.[filterKey] ?? {};

const initializeFilters = tableKey => {
  return _.cloneDeep(TABLE_FILTERS)[tableKey].reduce((acc, cv) => ({
    ...acc,
    [cv.key]: cv
  }), {});
};

const state = () => {
  const keys = Object.keys(_.cloneDeep(TABLE_FILTERS));

  const filters = keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: initializeFilters(key)
    };
  }, {});

  return {
    filters
  };
};

const getters = {
  getCriteria: state => tableKey => {
    const filters = getTableFilters(state, tableKey);

    return Object.keys(filters)?.reduce((acc, tableKey) => {
      const cv = filters[tableKey];
      const criteria = cv?.criteria;

      return criteria ? [...acc, criteria] : acc;
    }, []);
  },
  getIsMoreEnabled: state => (tableKey, filterKey) => getFilter(state, tableKey, filterKey)?.isMoreEnabled ?? false,
  getDisplay: state => (tableKey, filterKey) => getFilter(state, tableKey, filterKey)?.display ?? false,
  getExpanded: state => (tableKey, filterKey) => getFilter(state, tableKey, filterKey)?.expanded ?? [],
  getCriteriaValue: state => (tableKey, filterKey) => getFilter(state, tableKey, filterKey)?.criteria?.v ?? [],
  getData: state => (tableKey, filterKey) => getFilter(state, tableKey, filterKey)?.data ?? [],
  getFilters: state => tableKey => state.filters[tableKey],
  getFilter: state => (tableKey, filterKey) => state.filters?.[tableKey]?.[filterKey],
  getFilterField: state => (tableKey, filterKey, field) => state.filters?.[tableKey]?.[filterKey]?.[field],
  filters: state => state.filters
};

const actions = {
  setFilter (context, data) {
    context.commit('SET_FILTER', data);
  },
  resetFilter (context, tableKey) {
    context.commit('RESET_FILTER', tableKey);
    localStorage.removeItem('filters');
  },
  addFilter (context, data) {
    context.commit('ADD_FILTER', data);
  },
  removeFilter (context, data) {
    context.commit('REMOVE_FILTER', data);
  },
  removeFilterCriteria (context, data) {
    context.commit('REMOVE_FILTER_CRITERIA', data);
  },
  setFilters (context, data) {
    context.commit('SET_FILTERS', data);
  },
  clearCriteria (context) {
    context.commit('CLEAR_CRITERIA');
  }
};

const mutations = {
  REMOVE_FILTER_CRITERIA (state, { tableKey, filterKey }) {
    if (!tableKey || !filterKey || !state.filters?.[tableKey]?.[filterKey]) {
      return;
    }

    delete state.filters[tableKey][filterKey].criteria;
    delete state.filters[tableKey][filterKey].data;

    localStorage.setItem('filters', JSON.stringify(state.filters));
  },
  ADD_FILTER (state, { tableKey, filterKey, ...value }) {
    const filters = state.filters?.[tableKey];
    const keys = Object.keys(filters);

    const index = keys.reduce((acc, key) => {
      const hasKey = key.includes(filterKey);
      const index = hasKey && key.split('-')[1];

      return index ? Math.max(acc, index) : acc;
    }, 0);

    const newKey = index || value?.reusable ? `${filterKey}-${index + 1}` : filterKey;

    state.filters[tableKey][newKey] = {
      ...value,
      key: newKey,
      display: true
    };
  },
  REMOVE_FILTER (state, { tableKey, filterKey }) {
    const hasIndex = filterKey.split('-')[1];

    if (!hasIndex) {
      state.filters[tableKey][filterKey] = {
        ...state.filters[tableKey][filterKey],
        criteria: null,
        display: false
      };
    } else {
      delete state.filters[tableKey][filterKey];
    }

    localStorage.setItem('filters', JSON.stringify(state.filters));
  },
  SET_FILTER (state, data = {}) {
    const { tableKey, filterKey, ...values } = data;

    if (!state.filters[tableKey]) {
      state.filters = {
        ...state.filters,
        [tableKey]: {}
      };
    }

    if (!state.filters[tableKey][filterKey]) {
      state.filters[tableKey] = {
        ...state.filters[tableKey],
        [filterKey]: {}
      };
    }

    state.filters[tableKey][filterKey] = {
      ...state.filters[tableKey][filterKey],
      ...values
    };

    localStorage.setItem('filters', JSON.stringify(state.filters));
  },
  SET_FILTERS (state, data) {
    const getTableFilters = tableKey => Object.keys(data[tableKey]).reduce((acc, key) => {
      const updatedValue = data[tableKey][key] ?? {};
      const isReusable = updatedValue?.reusable;
      const filterKey = isReusable ? key.split('-')[0] : key;
      const defaultValue = state.filters?.[tableKey]?.[filterKey] ?? {};

      return {
        ...acc,
        [key]: {
          ...defaultValue,
          ...updatedValue
        }
      };
    }, {});

    state.filters = Object.keys(data).reduce((acc, key) => {
      return {
        ...acc,
        [key]: getTableFilters(key)
      };
    }, {});

    localStorage.setItem('filters', JSON.stringify(state.filters));
  },
  RESET_FILTER (state, tableKey) {
    const tableFilters = state.filters[tableKey];

    state.filters[tableKey] = Object.keys(tableFilters).reduce((acc, key) => {
      const defaultRest = TABLE_FILTERS[tableKey].find(x => x.key === key);
      const { criteria, ...rest } = tableFilters[key];

      if (rest?.component === 'FilterCustom') {
        return {
          ...acc,
          [key]: defaultRest || rest
        };
      }

      return {
        ...acc,
        [key]: {
          ...(defaultRest || rest),
          name: (rest?.name || defaultRest?.name),
          display: (rest?.display || defaultRest?.display)
        }
      };
    }, {});

    localStorage.setItem('filters', JSON.stringify(state.filters));
  },
  CLEAR_CRITERIA (state) {
    Object.keys(state.filters).forEach(tableKey => {
      Object.keys(state.filters[tableKey]).forEach(filter => {
        delete state.filters[tableKey][filter].criteria;
        delete state.filters[tableKey][filter].data;
        delete state.filters[tableKey][filter].expanded;
        delete state.filters[tableKey][filter].isMoreEnabled;
      });
    });

    localStorage.setItem('filters', JSON.stringify(state.filters));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

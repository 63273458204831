<template>
  <button
    type="button"
    class="btn"
    :disabled="disabled"
    @click="onClick"
  >
    <VLoader
      v-if="isLoading"
      class="h-4 w-4 text-white opacity-70 mr-2 -ml-1"
      :stroke-width="2"
    />

    <slot />
  </button>
</template>

<script>
import VLoader from '@/components/VLoader';

export default {
  components: {
    VLoader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup (props, context) {
    // Methods
    const onClick = () => {
      if (props.isLoading || props.readonly) {
        return;
      }

      context.emit('click');
    };

    return {
      onClick
    };
  }
};
</script>

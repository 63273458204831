import { createI18n } from 'vue-i18n';
import en from './en';
import fr from './fr';

let overwrittenStrings = {};
const strings = window.OVERWRITTEN_STRINGS;

if (strings) {
  try {
    overwrittenStrings = JSON.parse(strings);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
}

export default createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  fallbackLocale: 'en',
  messages: {
    en: {
      ...en,
      ...overwrittenStrings?.en ?? {}
    },
    fr: {
      ...fr,
      ...overwrittenStrings?.fr ?? {}
    }
  }
});

import ALL_OPTIONS from '@/constants/options';

export default function grow ({ next, store, nextVue }) {
  const isGrowAccessAvailable = store.getters['client/hasModule']('GROW');
  const doesUserHaveGrowAccess = store.getters['user/grow_access'] === ALL_OPTIONS.YES.value;

  if (isGrowAccessAvailable && doesUserHaveGrowAccess) {
    return next();
  }

  return nextVue({ name: 'error' });
}

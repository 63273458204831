export default function hasModule (...modules) {
  return ({ next, store, nextVue }) => {
    const isAuthorized = modules.some(module => store.getters['client/hasModule'](module));

    if (!isAuthorized) {
      return nextVue({ name: 'error' });
    }

    return next();
  };
}

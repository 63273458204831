import CRUDService from '@/services/hrsg/CRUDService';

class TaskService extends CRUDService {
  constructor () {
    super('platform.tasks');
  }

  async toggleStar (id) {
    return await this.request({
      endpoint: `${this.endpoint}.star`,
      pathParams: { id }
    });
  }
}

export default new TaskService();

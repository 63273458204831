<template>
  <div>
    <VNotification
      :item="notification"
      @toggle-read="$emit('toggle-read',notification)"
      @close="$emit('close')"
    >
      <template #header>
        <span v-if="notification.data?.updatedByName">
          {{ $t(`notification_${notification.type}`,{ name: notification.data?.updatedByName }) }}
        </span>
      </template>
      <template #type>
        {{ $t('notification_job_collaboration', { job: notification.data?.jobName }) }}
      </template>
      <div class="pt-1.5 !text-xs">
        {{ $t('from_to', { from: notification.data?.contributorTypeOld,to: notification.data?.contributorTypeNew }) }}
      </div>
    </VNotification>
  </div>
</template>

<script>
// Components
import VNotification from '@/components/templates/notifications/VNotification';
export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close']
};
</script>

import { ref } from 'vue';

export default function useInfiniteScrolling () {
  const RELOAD_PERCENTAGE = 0.85;
  const page = ref(1);
  const hasAllResults = ref(false);
  const isLoading = ref(false);

  const getMaxScrollTop = el => el?.scrollHeight - el?.clientHeight;

  const getScrollPercentage = el => {
    const scrollPosition = el?.scrollTop;
    const maxScrollTop = getMaxScrollTop(el);
    return maxScrollTop === 0 ? 0 : scrollPosition / maxScrollTop;
  };

  const scroll = (el, fn) => {
    if (!el) return;

    let ticking = false;

    el.addEventListener('scroll', () => {
      if (ticking) return;
      if (hasAllResults.value) return;
      if (isLoading.value) return;

      window.requestAnimationFrame(async () => {
        if (getScrollPercentage(el) >= RELOAD_PERCENTAGE) {
          isLoading.value = true;
          await fn();
          isLoading.value = false;
        }

        ticking = false;
      });

      ticking = true;
    });
  };

  return {
    isLoading,
    page,
    hasAllResults,
    scroll,
    getMaxScrollTop
  };
}

import CRUDService from '@/services/hrsg/CRUDService';

export class JobTypeService extends CRUDService {
  constructor () {
    super('administration.job-types');
  }

  async reorder (data) {
    return await this.request({
      endpoint: `${this.endpoint}.reorder`,
      pathParams: this.getPathvars(),
      data
    });
  }
}

export default new JobTypeService();

<template>
  <div class="relative">
    <button
      ref="buttonRef"
      type="button"
      class="flex items-center justify-center rounded-full w-[20px] h-[20px]"
      :class="{ 'bg-orange-1': !isDisabled }"
      @click="isShowing = !isShowing"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <VTooltip
        class="text-sm leading-[0]"
        :tooltip="$t('pending_job_updates')"
        responsive
        :tip-class="showTooltip ? 'block' : 'hidden'"
        direction="ssw"
      >
        <span
          class="block"
          :class="{ 'animate-spin': isActive }"
        >
          <VIcon
            icon="sync"
            :size="isDisabled ? 'text-[20px]' : 'text-[18px]'"
            :color="isDisabled ? '' : 'text-white'"
            class="-scale-x-100"
            @click="showTooltip = false"
          />
        </span>
      </VTooltip>
    </button>

    <VFlyout
      :show="isShowing"
      :include="[buttonRef]"
      class="absolute bottom-4 lg:right-[460px] right-[220px]"
      content-class="!pl-6 !pr-5 !py-6"
      @hide="isShowing = false"
    >
      <template #content>
        <div class="lg:w-[450px] w-[350px] text-grey-1">
          <div class="flex items-center justify-between mb-4">
            <h3>
              {{ $t('pending_job_updates', 2) }}
            </h3>

            <XIcon
              class="cursor-pointer text-grey-4 h-4.75 w-4.75"
              @click="isShowing = false"
            />
          </div>

          <div
            v-if="isActive"
            class="grid gap-2"
          >
            <div>
              {{ $t('process_job_updates_standby_message') }}
            </div>

            <div>
              {{ $t('send_email_when_done_message', { email }) }}
            </div>

            <div class="mt-5">
              <VButton
                class="btn-lg primary"
                @click="onClickHide"
              >
                {{ $t('close') }}
              </VButton>
            </div>
          </div>

          <div v-else-if="isDisabled">
            <div>
              {{ $t('no_pending_job_updates_message') }}
            </div>

            <div class="mt-5">
              <VButton
                class="btn-lg primary"
                @click="onClickHide"
              >
                {{ $t('close') }}
              </VButton>
            </div>
          </div>

          <div
            v-else
            class="grid gap-5"
          >
            <div v-if="hasPermissions(permissions.CONTENT_ADMINISTRATOR)">
              {{ $t('pending_job_updates_descripton') }}
            </div>

            <div
              v-else-if="hasPermissions(permissions.MANAGE_JOBS)"
              class="grid gap-2"
            >
              <div>
                {{ $t('pending_job_updates_descripton') }}
              </div>

              <div>
                {{ $t('manage_jobs_apply_updates') }}
              </div>
            </div>

            <div v-else>
              {{ $t('manage_libraries_apply_updates') }}
            </div>

            <VButton
              v-if="hasPermissions(permissions.CONTENT_ADMINISTRATOR)"
              class="text"
              @click="onClickToggleAll"
            >
              {{ $t('select_all') }}
            </VButton>

            <div
              ref="scrollElement"
              class="grid gap-3.5 overflow-y-auto"
            >
              <div
                v-for="item in displayedData"
                :key="item?.id"
                class="flex items-start gap-3.5 border-t pt-4"
              >
                <VCheckbox
                  :model-value="isSelected(item?.type)"
                  :disabled="item?.disabled"
                  @update:modelValue="onUpdateValue($event, item?.type)"
                />

                <div>
                  <div class="font-medium mb-1.5">
                    {{ item?.text }}
                  </div>

                  <div class="flex items-center gap-3 text-grey-2 !text-xs">
                    <div class="truncate">
                      {{ $h.getFullName(item?.last_updated_by) }}
                    </div>

                    <div>
                      {{ format(item?.updated_at, 'MMM D, YYYY - h:mm A') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isApplyChangesEnabled">
              <VButton
                class="warning btn-lg primary"
                :disabled="!forMeta.valid"
                @click="onClickApplyChanges"
              >
                {{ $t('apply_updates', 2) }}
              </VButton>
            </div>

            <div
              v-else
              class="grid gap-4"
            >
              <div class="border-t pt-3 -mr-5 -ml-6" />

              <div>
                <VSelect
                  v-model="typeValue"
                  :label="$t('job_update_label')"
                  required
                  :options="options"
                  :placeholder="$t('select')"
                >
                  <template #list-item-text="{ option }">
                    <div class="flex items-center gap-2">
                      <div>
                        {{ option?.text }}
                      </div>

                      <VTooltip
                        v-if="option.value"
                        fixed
                        responsive
                        direction="ese"
                      >
                        <template #content>
                          <ul class="pl-3 list-disc overflow-visible">
                            <li
                              v-for="(item, index) in option?.tooltipTexts"
                              :key="`${option.value}-${index}`"
                              :class="{ 'mt-1': index !== 0 }"
                            >
                              {{ item }}
                            </li>
                          </ul>
                        </template>

                        <VIcon
                          icon="info"
                          size="text-[15px]"
                          class="mt-1.5 hovered-icon"
                        />
                      </VTooltip>
                    </div>
                  </template>
                </VSelect>
              </div>

              <div>
                <VButton
                  class="warning btn-lg primary mt-1.5"
                  :disabled="!meta?.valid"
                  @click="onClickConfirm"
                >
                  {{ $t('confirm') }}
                </VButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </VFlyout>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import store from '@/store';
// Composables
import useDate from '@/composables/useDate';
import usePermissions from '@/composables/usePermissions';
// Components
import VFlyout from '@/components/VFlyout';
import VIcon from '@/components/VIcon';
import VSelect from '@/components/inputs/VSelect';
import VCheckbox from '@/components/inputs/VCheckbox';
import VButton from '@/components/VButton';
import VTooltip from '@/components/VTooltip';
import { XIcon } from '@heroicons/vue/solid';

export default {
  components: {
    VFlyout,
    VIcon,
    VButton,
    VCheckbox,
    VSelect,
    VTooltip,
    XIcon
  },
  setup () {
    // Misc
    const { t } = useI18n();

    // Composables
    const { dayjs, format } = useDate();
    const { permissions, hasPermissions } = usePermissions();

    // Validation
    const { resetForm, meta, values, errors } = useForm({
      validationSchema: {
        updates_for: yup.array().required().min(1).of(yup.string()),
        update_type: yup.string().required()
      },
      initialValues: {
        updates_for: [],
        update_type: null
      }
    });

    const { value: forValue, meta: forMeta } = useField('updates_for');
    const { value: typeValue } = useField('update_type');

    // Data
    const isShowing = ref(false);
    const isApplyChangesEnabled = ref(false);
    const scrollElement = ref();
    const buttonRef = ref();
    const showTooltip = ref(false);

    // Computed
    const isDisabled = computed(() => data.value?.length === 0);
    const isActive = computed(() => store.getters['pendingJobUpdates/isActive']);
    const email = computed(() => store.getters['pendingJobUpdates/initiatedBy']?.email);

    const types = computed(() => ({
      CONTENT_DEFAULTS: {
        text: t('content_defaults_updated')
      },
      JOB_TEMPLATE: {
        text: t('job_template_updated')
      },
      // Libraries
      ADDITIONAL_INFORMATION: {
        text: t('additional_information_updated'),
        permissions: [permissions.MANAGE_JOBS]
      },
      CERTIFICATIONS: {
        text: t('certifications_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      COMPETENCIES: {
        text: t('competencies_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      EDUCATIONS: {
        text: t('educations_updated'),
        permissions: [permissions.MANAGE_JOBS]
      },
      EXPERIENCES: {
        text: t('experiences_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      SKILLS: {
        text: t('skills_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      LANGUAGES: {
        text: t('languages_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      RESPONSIBILITIES: {
        text: t('responsibilities_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      },
      WORKING_CONDITIONS: {
        text: t('working_conditions_updated', 2),
        permissions: [permissions.MANAGE_JOBS]
      }
    }));

    const data = computed(() => store.getters['pendingJobUpdates/data']);
    const isStoreShowing = computed(() => store.getters['pendingJobUpdates/isShowing']);

    const displayedData = computed(() => {
      const GROUPS = [
        {
          text: t('architecture_updated'),
          type: 'ARCHITECTURE',
          group: [
            'ARCHITECTURE_CORE',
            'ARCHITECTURE_JOB_FAMILY',
            'ARCHITECTURE_LEADERSHIP',
            'ARCHITECTURE_CAREER_STREAM'
          ]
        },
        {
          text: t('scales_updated'),
          type: 'SCALES',
          group: [
            'SCALE_LANGUAGE',
            'SCALE_LANGUAGE_LEVEL',
            'SCALE_SKILL',
            'SCALE_SKILL_LEVEL',
            'SCALE_EDUCATION',
            'SCALE_EDUCATION_LEVEL'
          ]
        },
        {
          text: t('job_updated'),
          type: 'JOB',
          permissions: [permissions.MANAGE_JOBS],
          group: [
            'JOBS_ASSIGN_LEVEL',
            'JOBS_ASSIGN_TYPE',
            'JOBS_MOVE'
          ]
        },
        {
          text: t('job_properties_updated'),
          type: 'JOB_PROPERTIES',
          group: [
            'PROPERTY_LOCATIONS',
            'PROPERTY_JOB_GROUPS',
            'PROPERTY_JOB_LEVELS',
            'PROPERTY_JOB_SALARY',
            'PROPERTY_JOB_TYPE',
            'JOB_PROPERTIES',
            'PROPERTY_OPTIONS'
          ]
        }
      ];

      return data.value?.reduce((acc, cv) => {
        const getDisabled = p => !hasPermissions(p) && !hasPermissions(permissions.CONTENT_ADMINISTRATOR);

        const group = GROUPS.find(x => x.group.includes(cv?.type));
        if (!group) {
          acc.push({
            ...cv,
            ...types.value[cv?.type] ?? {},
            disabled: getDisabled(types.value[cv?.type]?.permissions)
          });
          return acc;
        }

        const index = acc.findIndex(x => x?.mainType === group.type);
        if (index === -1) {
          acc.push({
            ...cv,
            text: group?.text,
            mainType: group?.type,
            type: [cv?.type],
            disabled: getDisabled(group?.permissions)
          });
          return acc;
        }

        if (dayjs(cv?.updated_at).isAfter(acc[index].updated_at)) {
          acc[index].updated_at = cv?.updated_at;
          acc[index].last_updated_by = cv?.last_updated_by;
        }

        acc[index].type.push(cv?.type);
        return acc;
      }, []);
    });

    const options = computed(() => [
      {
        text: t('job_update_silent_text'),
        subtext: t('job_update_silent_subtext'),
        activeSubtext: 'description-text',
        tooltipTexts: [
          t('creates_new_published_versions_of_published_jobs', 2),
          t('does_not_provide_a_process_for_notifying_incumbents', 2)
        ],
        value: 'silent'
      },
      {
        text: t('job_update_controlled_text'),
        subtext: t('job_update_controlled_subtext'),
        activeSubtext: 'description-text',
        tooltipTexts: [
          t('creates_new_draft_versions_of_published_jobs_for_review', 2),
          t('provide_a_process_for_notifying_incumbents', 2)
        ],
        value: 'controlled'
      }
    ]);

    // Methods
    const setIsShowing = value => {
      isShowing.value = value;
      if (!value) {
        setIsApplyChangesEnabled(false);
      }
    };

    const setIsApplyChangesEnabled = value => {
      isApplyChangesEnabled.value = value;
    };

    const setForValue = value => {
      forValue.value = value;
    };

    const isSelected = value => {
      return Array.isArray(value) ? forValue.value.some(x => value.includes(x)) : forValue.value?.includes(value);
    };

    const onUpdateValue = (value, type) => {
      if (!value) {
        setForValue(forValue.value.filter(x => {
          return Array.isArray(type) ? !type.includes(x) : x !== type;
        }));
        return;
      }

      setForValue([...new Set([...forValue.value, ...Array.isArray(type) ? type : [type]])]);
    };

    const onClickToggleAll = () => {
      if (data.value?.length === forValue.value?.length) {
        setForValue([]);
        return;
      }

      setForValue(data.value?.map(x => x?.type));
    };

    const onClickConfirm = async () => {
      await store.dispatch('pendingJobUpdates/initiate', values);
      resetForm();
    };

    // Watchers
    watch(scrollElement, () => {
      if (!scrollElement.value) return;

      scrollElement.value.style.maxHeight = `${((window.innerHeight - 115) * 50) / 100}px`;
    });

    watch(data, () => {
      setForValue(displayedData.value?.reduce((acc, cv) => {
        return cv?.disabled ? acc : [...acc, ...Array.isArray(cv?.type) ? cv.type : [cv.type]];
      }, []));
    });

    watch(isStoreShowing, newValue => {
      if (!newValue) return;

      isShowing.value = newValue;
    }, {
      immediate: true
    });

    return {
      buttonRef,
      permissions,
      scrollElement,
      email,
      isActive,
      isDisabled,
      displayedData,
      options,
      isShowing,
      isApplyChangesEnabled,
      onClickHide: () => setIsShowing(false),
      onClickApplyChanges: () => setIsApplyChangesEnabled(true),
      isSelected,
      onUpdateValue,
      onClickToggleAll,
      onClickConfirm,
      // usePermissions
      hasPermissions,
      // useField
      forValue,
      forMeta,
      typeValue,
      // useForm
      errors,
      meta,
      // useDate
      format,
      showTooltip
    };
  }
};
</script>

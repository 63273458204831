<template>
  <VNotification
    :item="notification"
    @toggle-read="$emit('toggle-read',notification)"
    @close="$emit('close')"
  >
    <template #header>
      <span>
        {{ $t(`notification_${notification.type}`,{ name: actionTakenBy }) }}
      </span>
    </template>
    <template #type>
      <span v-if="notification.type === 'JobPublished'">
        {{ $t('notification_job_collaboration', { job: notification.data?.jobName }) }}
      </span>
      <span v-else>
        {{ $t('notification_job_incumbents', { job: notification.data?.jobName }) }}
      </span>
    </template>
    <div
      v-if="notification.type === 'JobPublished'"
      class="mt-1 !text-grey-2 !text-xs"
    >
      {{ $t('published_job_thanks_msg') }}
    </div>
  </VNotification>
</template>

<script>
import { computed } from 'vue';
// Components
import VNotification from '@/components/templates/notifications/VNotification';
export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props) {
    const actionTakenBy = computed(() => {
      const { notification: { type, data } } = props;
      if (type === 'JobArchived') {
        return data?.archivedByName;
      } else if (type === 'JobDeleted') {
        return data?.deletedByName;
      }
      return data?.publisherName;
    });
    return {
      actionTakenBy
    };
  }
};
</script>

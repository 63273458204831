import LibraryService from '@/services/hrsg/content/libraries/LibraryService';
import useDownload from '@/composables/useDownload';
const { downloadFile } = useDownload();

class CompetencyService extends LibraryService {
  constructor () {
    super('content.competencies');
  }

  async edit (id) {
    return await this.request({
      endpoint: `${this.endpoint}.edit`,
      pathParams: { id }
    });
  }

  async duplicate (id) {
    return await this.request({
      endpoint: `${this.endpoint}.duplicate`,
      pathParams: { id }
    });
  }

  async pdf (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.pdf`,
      pathParams: { id },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async docx (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.docx`,
      pathParams: { id },
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async multiplePDF (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.multiple-pdf`,
      data,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async multipleDocx (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.multiple-docx`,
      data,
      responseType: 'blob',
      passHeaders: true
    });

    downloadFile(response);
  }

  async move (id, data) {
    return await this.request({
      endpoint: `${this.endpoint}.move`,
      pathParams: { id },
      data
    });
  }

  async jobComparisons (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.job-comparisons`,
      pathParams: { id }
    });

    return response?.payload?.data ?? [];
  }
}

export default new CompetencyService();

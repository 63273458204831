export default [
  'ACCESS_ANALYTICS',
  'ACCESS_AUDIT_LOGS',
  'ACCESS_INTERVIEW_GUIDE_TEMPLATES',
  'ACCESS_JOBS',
  'ACCESS_LIBRARIES',
  'ACCESS_ORG_CHART',
  'ACCOUNT_ADMINISTRATOR',
  'COMMUNICATIONS_ADMINISTRATOR',
  'CONTENT_ADMINISTRATOR',
  'EXPORT_JOBS',
  'EXPORT_LIBRARIES',
  'EXPORT_ORG_CHART',
  'JOBS_COLLABORATION',
  'MANAGE_INTERVIEW_QUESTIONS',
  'MANAGE_INTERVIEWS',
  'MANAGE_JOBS',
  'MANAGE_LIBRARIES',
  'MANAGE_LOCATIONS',
  'MANAGE_MY_ACCOUNT',
  'MANAGE_SIGNOFFS',
  'MANAGE_SURVEYS',
  'MANAGE_TEAM',
  'OWN_JOBS',
  'PERMISSIONS_ADMINISTRATOR',
  'PERSONIFY',
  'PUSH_JOB_POSTS',
  'USERS_ADMINISTRATOR',
  'VIEW_HELP',
  'MANAGE_PEOPLE'
].reduce((acc, cv) => ({ ...acc, [cv]: cv }), {});

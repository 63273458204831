const state = () => {
  return {
    jobTemplates: [],
    indirectlyUpdateType: null,
    indirectlyUpdatedIds: [],
    jobSelectedLibraryItemsId: {},
    jobHistory: [],
    jobExportSettings: {},
    levelComparisonSelectedJobs: {}
  };
};

const getters = {
  indirectlyUpdateType: state => state.indirectlyUpdateType,
  indirectlyUpdatedIds: state => state.indirectlyUpdatedIds,
  jobSelectedLibraryItemsId: state => state.jobSelectedLibraryItemsId,
  jobTemplates: state => state.jobTemplates,
  jobHistory: state => state.jobHistory,
  jobExportSettings: state => state.jobExportSettings,
  levelComparisonSelectedJobs: state => state.levelComparisonSelectedJobs
};

const actions = {
  setIndirectlyUpdatedIds ({ commit }, value) {
    commit('SET_INDIRECTLY_UPDATE_TYPE', value?.type);

    if (!value?.ids) return;
    commit('SET_INDIRECTLY_UPDATED_IDS', value?.ids);
  },
  unsetIndirectlyUpdatedIds ({ commit }) {
    commit('UNSET_INDIRECTLY_UPDATED_IDS');
  },
  setJobSelectedLibraryItemsId ({ commit }, value = {}) {
    commit('SET_JOB_SELECTED_LIBRARY_ITEMS_ID', value);
  },
  updateJobSelectedLibraryItemsId (context, value) {
    const { key, ids } = value;
    const newValue = { ...context.state.jobSelectedLibraryItemsId, [key]: { ids } };
    context.commit('SET_JOB_SELECTED_LIBRARY_ITEMS_ID', newValue);
  },
  setJobTemplates ({ commit }, value = []) {
    let dataToCommit = value;
    if (value.length === 0) {
      const localData = localStorage.getItem('jobTemplates');

      if (localData) {
        dataToCommit = JSON.parse(localData);
      }
    }
    if (dataToCommit) {
      commit('SET_JOB_TEMPLATES', dataToCommit);
    }
  },
  setJobHistory ({ commit }, value = []) {
    let dataToCommit = value;
    if (value.length === 0) {
      const localData = localStorage.getItem('jobHistory');

      if (localData) {
        dataToCommit = JSON.parse(localData);
      }
    }

    commit('SET_JOB_HISTORY', dataToCommit);
  },

  setJobExportSettings ({ commit, rootGetters }, value = {}) {
    const userId = rootGetters['user/user']?.id;
    const userWiseData = JSON.parse(localStorage.getItem('jobExportSettings')) || {};

    if (userWiseData[userId]) {
      userWiseData[userId] = { ...userWiseData[userId], ...value };
    } else {
      userWiseData[userId] = value;
    }

    commit('SET_JOB_EXPORT_SETTINGS', userWiseData);
  },

  setLevelComparisonSelectedJobs ({ commit }, { jobId, element }) {
    commit('SET_LEVEL_COMPARISON_SELECTED_JOBS', { jobId, element });
  }

};

const mutations = {
  SET_INDIRECTLY_UPDATE_TYPE (state, value) {
    state.indirectlyUpdateType = value;
  },
  SET_INDIRECTLY_UPDATED_IDS (state, value) {
    const hasId = state.indirectlyUpdatedIds.includes(value);
    if (hasId) return;

    state.indirectlyUpdatedIds.push(value);
  },
  UNSET_INDIRECTLY_UPDATED_IDS (state) {
    state.indirectlyUpdatedIds = [];
  },
  SET_JOB_SELECTED_LIBRARY_ITEMS_ID (state, value) {
    state.jobSelectedLibraryItemsId = value;
  },
  SET_JOB_TEMPLATES (state, value) {
    state.jobTemplates = value;
    localStorage.setItem('jobTemplates', JSON.stringify(value));
  },
  SET_JOB_HISTORY (state, value) {
    state.jobHistory = value;
    localStorage.setItem('jobHistory', JSON.stringify(value));
  },
  SET_JOB_EXPORT_SETTINGS (state, value) {
    state.jobExportSettings = value;
    localStorage.setItem('jobExportSettings', JSON.stringify(value));
  },
  SET_LEVEL_COMPARISON_SELECTED_JOBS (state, { jobId, element }) {
    if (!state.levelComparisonSelectedJobs[jobId]) {
      state.levelComparisonSelectedJobs[jobId] = [element];
    } else {
      const index = state.levelComparisonSelectedJobs[jobId].findIndex(item => item.handle === element.handle);
      if (index !== -1) {
        state.levelComparisonSelectedJobs[jobId][index] = element;
      } else {
        state.levelComparisonSelectedJobs[jobId].push(element);
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
